@import "assets/scss/utils.scss";

.unsubscribeWrapper {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100vw;
  background: #000000;
  transition: 0.8s all ease;
  z-index: z-index("unsubscribe");

  display: flex;
  align-items: center;
  justify-content: center;

  .borderWrapper {
    border: 2px solid #ffffff;
    border-radius: 38px;
    width: 50vw;

    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    @include devicesMax(tablet) {
      width: 80vw;
    }

    @include devicesMax(mobile) {
      border: none;
      width: 96vw;
    }

    .upset {
      position: absolute;
      top: -5vw;
      left: 2vw;
      width: 10vw;

      @include devicesMax(tablet) {
        top: -6vw;
        left: 2vw;
        width: 12vw;
      }

      @include devicesMax(mobile) {
        display: none;
      }
    }

    .greeting {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 30vw;

      @include devicesMax(tablet) {
        height: 40vw;
      }

      h1 {
        width: 70%;
        font-size: 4.5vw;
        font-family: VanguardCF-DemiBold;
        color: #ffffff;
        text-align: center;
        margin-bottom: 5vw;

        @include devicesMax(tablet) {
          font-size: 7vw;
        }

        @include devicesMax(mobile) {
          font-size: 2rem;
        }
      }

      p {
        font-size: 18px;
        font-family: VisbyCF-Medium;
        color: #ffffff;
        text-align: center;

        @include devicesMax(mobile) {
          font-size: 14px;
          width: 90vw;
        }
      }

      .btn {
        padding: 0.25vw 3vw;
        background: transparent;
        box-shadow: 0 0 0 1pt #f2f2f2;
        outline: none;
        border-radius: 28px;
        text-decoration: none;
        border: none;
        cursor: none;
        @extend .flexCenterX;

        @include devicesMax(tabletMini) {
          width: 25vw;
        }

        @include devicesMax(mobile) {
          width: 40vw;
        }

        .btnText {
          color: #f8f9fa;
          font-family: VanguardCF-Regular;
          font-size: 2vw;
          line-height: 100%;
          transform: translateY(-0.3ch);
          white-space: nowrap;
          @extend .cursorPointer;

          @include devicesMax(tablet) {
            font-size: 3.5vw;
          }

          @include devicesMax(mobile) {
            font-size: 6vw;
          }
        }

        @include devicesMin(aboveTablet) {
          &:hover {
            background: #d9ff51;
            box-shadow: 0 0 0 1pt #d9ff51;
            outline: none;

            .btnText {
              color: #000000;
            }
          }
        }
      }
    }

    .reason {
      padding: 2vw 0;

      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      @include devicesMax(tablet) {
        padding: 5vw 0;
      }

      @include devicesMax(mobile) {
        padding: 10vw 0;
      }

      h1 {
        font-size: 3.25rem;
        font-family: VanguardCF-DemiBold;
        color: #ffffff;
        margin-bottom: 1.5vw;
        text-align: center;

        @include devicesMax(mobile) {
          font-size: 2rem;
          margin-bottom: 4vw;
        }

        span {
          font-family: Ageya-Oblique;
          font-size: 2.75rem;

          @include devicesMax(mobile) {
            font-size: 2rem;
          }
        }
      }

      p {
        font-size: 18px;
        font-family: VisbyCF-Medium;
        color: #ffffff;
        margin-bottom: 2vw;
        text-align: center;

        @include devicesMax(mobile) {
          font-size: 12px;
          margin-bottom: 4vw;
          line-height: 140%;
          width: 80vw;
        }
      }

      .formWrapper {
        @include devicesMax(tabletMini) {
          margin-left: 0;
        }

        @include devicesMax(mobile) {
          margin-left: 10vw;
        }

        .privacyCheck {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 0.75rem 0;

          @include devicesMax(desktopSmall) {
            padding: 0.5rem 0;
          }

          @include devicesMax(mobile) {
            padding: 0.5rem 0;
          }

          .radioWrapper {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 1rem;

            @include devicesMax(mobile) {
              gap: 0.5rem;
            }

            .customRadio {
              flex-shrink: 0;
              height: 18px;
              width: 18px;

              border-radius: 50%;
              border: 2px solid #ffffff;
              position: relative;
              opacity: 0.3;

              @include devicesMax(mobile) {
                height: 14px;
                width: 14px;
              }

              .radioCheck {
                @extend .absoluteCenter;

                height: 6px;
                width: 6px;
                border-radius: 50%;
                background: #ffffff;
              }
            }

            .radioText {
              display: flex;
              align-items: center;
              justify-content: flex-start;

              p {
                font-family: VisbyCF-Regular;
                font-size: 16px;
                color: #ffffff;
                opacity: 0.6;
                padding: 0;
                margin: 0;

                @include devicesMax(mobile) {
                  text-align: left;
                  font-size: 12px;
                }

                br {
                  display: none;

                  @include devicesMax(tablet) {
                    display: block;
                  }

                  @include devicesMax(mobile) {
                    display: none;
                  }
                }
              }
            }
          }

          .subscribeError {
            p {
              font-family: VisbyCF-Regular;
              font-size: 1rem;
              color: #ffffff;
              opacity: 0.6;
              padding: 0;
              margin: 0;

              @include devicesMax(tabletMini) {
                padding-right: 2.5rem;
              }

              @include devicesMax(mobile) {
                padding-right: 0;
              }
            }

            @include devicesMax(tabletMini) {
              margin-left: auto;
              margin-right: auto;
            }
          }
        }

        .btn {
          padding: 0.25vw 4vw 0.35vw 4vw;
          background: transparent;
          box-shadow: 0 0 0 1pt #f2f2f2;
          outline: none;
          border-radius: 28px;
          text-decoration: none;
          border: none;
          cursor: none;
          margin: 0 auto;
          margin-top: 2rem;
          @extend .flexCenterX;
          transition: 0.4s all ease;

          opacity: 1;
          pointer-events: auto;

          &.disabled {
            opacity: 0.3;
            pointer-events: none;
          }

          @include devicesMin(aboveTablet) {
            &:hover {
              background: #d9ff51;
              box-shadow: 0 0 0 1pt #d9ff51;
              outline: none;

              .btnText {
                color: #000000;
              }
            }
          }

          @include devicesMax(tabletMini) {
            width: 25vw;
          }

          @include devicesMax(mobile) {
            width: 45vw;
            margin-top: 10vw;
            margin-left: 15vw;
          }

          .btnText {
            color: #f8f9fa;
            font-family: VanguardCF-Regular;
            font-size: 1.75vw;
            line-height: 100%;
            margin: 0;
            transform: translateY(-2px);
            white-space: nowrap;
            cursor: none;

            @include devicesMax(tabletMini) {
              font-size: 4.25vw;
            }

            @include devicesMax(mobile) {
              font-size: 7vw;
            }
          }
        }
      }
    }

    .resubscribedSuccessfully {
      font-size: 18px;
      font-family: VisbyCF-Medium;
      color: #ffffff;
      margin-top: 2vw;
      text-align: center;

      @include devicesMax(mobile) {
        font-size: 12px;
        margin-bottom: 4vw;
        line-height: 140%;
        width: 80vw;
      }

      @include devicesMax(desktopSmall) {
        margin-top: 5%;
      }

      @include devicesMax(tabletMini) {
        margin-top: 10%;
      }

      @include devicesMax(tabletMini) {
        margin-top: 15%;
      }
    }

    .resubscribe {
      margin-top: 10%;

      font-size: 18px;
      font-family: VisbyCF-Medium;
      color: #ffffff;
      margin-bottom: 2vw;
      text-align: center;

      @include devicesMax(mobile) {
        font-size: 12px;
        margin-bottom: 4vw;
        line-height: 140%;
        width: 80vw;
      }

      @include devicesMax(desktopSmall) {
        margin-top: 5%;
      }

      @include devicesMax(tabletMini) {
        margin-top: 10%;
      }

      @include devicesMax(tabletMini) {
        margin-top: 15%;
      }
    }

    .alreadyUnsubscribed {
      @include dims(40vw, 20vw);

      @include devicesMax(mobile) {
        @include dims(65vw, 20vw);
      }

      display: flex;
      align-items: center;
      justify-content: center;

      .already {
        font-family: VisbyCF-Regular;
        font-size: 20px;
        color: #ffffff;
        padding: 0;
        margin: 0;

        @include devicesMax(mobile) {
          font-size: 16px;
          line-height: 180%;
          text-align: center;
        }

        br {
          display: none;

          @include devicesMax(tablet) {
            display: block;
          }

          @include devicesMax(mobile) {
            display: none;
          }
        }
      }
    }

    .seeYou {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 5vw 0;

      h1 {
        width: 100%;
        font-size: 10rem;
        font-family: VanguardCF-DemiBold;
        color: #ffffff;
        margin-bottom: 5vw;
        text-align: center;

        @include devicesMax(tabletMini) {
          margin-top: 7vw;
          margin-bottom: 7vw;
        }

        @include devicesMax(mobile) {
          font-size: 4rem;
          margin-bottom: 12vw;
        }
      }

      .btn {
        padding: 0.25vw 4vw 0.35vw 4vw;
        background: transparent;
        box-shadow: 0 0 0 1pt #f2f2f2;
        outline: none;
        border-radius: 28px;
        text-decoration: none;
        border: none;
        cursor: none;

        @extend .flexCenterX;
        @include devicesMax(tabletMini) {
          width: 25vw;
        }

        @include devicesMax(mobile) {
          width: 45vw;
        }

        .btnText {
          color: #f8f9fa;
          font-family: VanguardCF-Regular;
          font-size: 2.5vw;
          line-height: 100%;
          margin: 0;
          transform: translateY(-2px);
          white-space: nowrap;
          cursor: none;

          @include devicesMax(tabletMini) {
            font-size: 4.25vw;
          }

          @include devicesMax(mobile) {
            font-size: 7vw;
          }
        }

        @include devicesMin(aboveTablet) {
          &:hover {
            background: #d9ff51;
            box-shadow: 0 0 0 1pt #d9ff51;
            outline: none;

            .btnText {
              color: #000000;
            }
          }
        }
      }
    }
  }
}
