@import "assets/scss/utils.scss";

.home {
  position: relative;

  .intro {
    padding: 0 5vw 10vw 5vw;

    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    @include devicesMax(tabletMini) {
      flex-direction: column;
      align-items: center;
      padding: 20vw 0 0 0;
      height: auto;
    }

    @include devicesMax(mobile) {
      align-items: center;
      padding: 20vw 0 10vw 0;
    }

    .imgContainer {
      @include position(absolute, 30vw, auto, auto, 3.5%);
      @include dims(47vw, 40vw);
      transform: rotate(20deg);
      z-index: 10;

      @include devicesMax(tabletMini) {
        display: none;
      }

      .img {
        @include dims(100%);
        display: block;
        object-fit: contain;
      }
    }

    .textWrapper {
      position: absolute;
      color: #f8f9fa;
      padding-bottom: 7vw;

      @include devicesMax(tabletMini) {
        position: relative;
        padding-bottom: 12vw;
      }

      .lookAhead {
        transform: translateY(1vw);

        @include devicesMax(mobile) {
          transform: translateY(0);
        }
      }

      .title {
        font-size: 10vw;
        font-family: VanguardCF-ExtraBold;
        line-height: 100%;

        @include devicesMax(tabletMini) {
          font-size: 17vw;
        }

        @include devicesMax(mobile) {
          font-size: 20vw;
        }

        .oblique {
          font-family: Ageya-Oblique;
          font-weight: 100;
          font-size: 11.25vw;

          @include devicesMax(tabletMini) {
            font-size: 19vw;
          }

          @include devicesMax(mobile) {
            font-size: 23vw;
          }
        }
      }

      .hasArrived {
        transform: translateY(-2.25vw);

        @include devicesMax(tabletmini) {
          transform: translateY(-2.5vw);
        }

        @include devicesMax(mobile) {
          transform: translateY(-3vw);
        }
      }

      .small {
        color: #f8f9fa;
        font-family: VanguardCF-Regular;
        font-weight: 100;
        font-size: 2vw;
        line-height: 100%;

        @include devicesMax(tabletMini) {
          font-size: 4vw;
        }

        @include devicesMax(mobile) {
          font-size: 4.75vw;
        }
      }

      .textBottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 1vw;

        .small {
          margin-left: auto;

          @include devicesMax(mobile) {
            font-size: 4.75vw;
          }
        }
      }
    }

    .videoContainer {
      @include dims(32vw);
      margin-left: auto;
      margin-bottom: 2vw;
      margin-right: 2.5vw;
      border-radius: 24px;
      overflow: hidden;
      z-index: 10;
      @extend .cursorPointer;

      @include devicesMax(tabletMini) {
        @include dims(100vw, auto);
        border-radius: 0;
      }

      .video {
        @include dims(100%);
        display: block;
        object-fit: cover;
      }

      .playShowreelBtnTouch {
        margin: 4vw 4vw 10vw 4vw;
        @extend .flexCenterX;

        .btnText {
          color: #ffffff;
          font-family: VanguardCF-Regular;
          font-size: 6vw;
          line-height: 100%;
          text-align: left;
          transform: translateY(-0.1em);
        }

        .iconRightArrow {
          background: #d9ff51;
          border-radius: 50%;
          height: 8vw;
          width: 8vw;
          margin-left: 0.25rem;

          @extend .flexCenterX;

          .arrow {
            height: 10%;
            width: 10%;

            @include devicesMax(tablet) {
              height: 40%;
              width: 40%;
            }
          }
        }
      }
    }
  }

  .works {
    background: #f2f2f2;
    border-radius: 32px;
    overflow: hidden;
    padding-bottom: 6vw;
    @extend .flexCenterY;

    @include devicesMax(mobile) {
      border-radius: 0;
      padding-bottom: 50vw;
    }

    .marqueeContainer {
      width: 100%;
      padding: 2vw 0;

      @include devicesMax(mobile) {
        padding: 5vw 0 10vw 0;
      }

      .marqueeInner {
        span {
          color: #000000;
          font-family: VanguardCF-ExtraBold;
          font-size: 18vw;
          line-height: 100%;

          @include devicesMax(mobile) {
            font-size: 30vw;
          }
        }

        .rightMargin {
          margin-right: 2.5rem;

          @include devicesMax(mobile) {
            margin-right: 1.5rem;
          }
        }

        .inlineO {
          font-family: Ageya-Oblique;
          font-weight: 100;
          margin-left: -3vw;
          margin-right: 0;

          @include devicesMax(mobile) {
            margin-left: -5.5vw;
          }
        }
      }

      // .marqueeText {
      //   position: relative;
      //   display: flex;
      //   align-items: center;
      //   overflow: hidden;

      //   .mInner {
      //     position: relative;
      //     white-space: nowrap;
      //     padding: 0;
      //     margin: 0;
      //     will-change: transform;

      //     .spanWrapper {
      //       display: inline-block;

      //       span {
      //         color: #000000;
      //         font-family: VanguardCF-ExtraBold;
      //         font-size: 18vw;
      //         line-height: 100%;
      //         transform: skew(10deg);

      //         @include devicesMax(mobile) {
      //           font-size: 30vw;
      //         }
      //       }

      //       .rightMargin {
      //         margin-right: 2.5rem;

      //         @include devicesMax(mobile) {
      //           margin-right: 1.5rem;
      //         }
      //       }

      //       .inlineO {
      //         font-family: Ageya-Oblique;
      //         font-weight: 100;
      //         margin-left: -3vw;
      //         margin-right: 0;

      //         @include devicesMax(mobile) {
      //           margin-left: -5.5vw;
      //         }
      //       }
      //     }
      //   }
      // }
    }

    .projects {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 2vw;
      padding: 2vw;

      @include devicesMax(tabletMini) {
        grid-template-columns: repeat(1, 70vw);
        padding: 0;
      }

      @include devicesMax(mobile) {
        grid-template-columns: repeat(1, 92vw);
      }
    }

    .bottomTextWrapper {
      width: 60vw;
      position: relative;

      @include devicesMax(tablet) {
        display: none;
      }

      .firstLine {
        text-align: start;

        .inlineALook {
          display: inline-block;
          font-family: Ageya-Oblique;
          font-weight: 100;
          letter-spacing: 0px;
          margin: 0 1.5vw;

          .inlineA {
            display: inline-block;
            font-family: Ageya-Oblique;
            font-weight: 100;
            margin-right: 1vw;
          }

          .inlineOo {
            display: inline-block;
            font-family: Ageya-Oblique;
            font-weight: 100;
            letter-spacing: -1vw;
            margin-right: 1vw;
          }
        }
      }

      .secondLine {
        text-align: end;
        transform: translateY(-1.5vw) skew(-10deg);
      }

      .thirdLine {
        text-align: start;
        padding-left: 14vw;
        transform: translateY(-3vw);
      }

      h1 {
        font-family: VanguardCF-ExtraBold;
        font-weight: 400;
        font-size: 7vw;
        line-height: 100%;
        color: #000000;
        margin-top: 0;
        margin-bottom: 160px;
        margin: 0;

        .inlineO {
          font-family: Ageya-Oblique;
          font-weight: 100;
          margin-left: -1.25vw;
          margin-right: 0;
        }
      }

      .seeAllWorksAnimated {
        position: absolute;
        left: 50vw;
        bottom: 5vw;

        @include devicesMax(tablet) {
          display: none;
        }
      }
    }

    // .seeAllWorksBtnTouch {
    //   border: none;
    //   background: transparent;

    //   display: none;
    //   align-items: center;
    //   justify-content: center;
    //   gap: 0.5rem;

    //   @include devicesMax(tablet) {
    //     display: flex;
    //     gap: 1rem;
    //     margin-top: 5vw;
    //   }

    //   @include devicesMax(mobile) {
    //     margin-top: 0;
    //   }

    //   p {
    //     text-align: left;
    //     font-family: VanguardCF-Medium;
    //     font-size: 8vw;
    //     line-height: 100%;
    //     letter-spacing: 0px;
    //     color: #000000;
    //     margin: 0;
    //     transform: translateY(-3px);
    //   }

    //   .iconRightArrow {
    //     background: #d9ff51;
    //     border-radius: 50%;
    //     height: 13vw;
    //     width: 13vw;
    //     transform: rotate(45deg);

    //     @extend .flexCenterX;

    //     .arrow {
    //       height: 10%;
    //       width: 10%;

    //       @include devicesMax(tablet) {
    //         height: 30%;
    //         width: 30%;
    //       }
    //     }

    //     @include devicesMax(tablet) {
    //       height: 11vw;
    //       width: 11vw;
    //     }
    //   }
    // }

    .jdSmileyContainer {
      @include dims(100%, 45vw);
      margin-bottom: -22.5vw;
      position: relative;

      .seeAllWorkBtnC {
        @extend .cursorPointer;
        @include position(absolute, -7%, auto, auto, 57%);
        transform: rotate(7deg);

        @include devicesMax(mobile) {
          @include position(absolute, -5%, auto, auto, 30%);
          border-radius: 10px;
        }

        .seeAllWorkBtn {
          background: #fff;
          border-radius: 14px;
          border: 1px solid #000;
          padding: 0.5rem 4rem;

          @include devicesMax(mobile) {
            border-radius: 10px;
          }

          &:hover {
            background: #d9ff51;
          }

          .btnText {
            color: #000;
            font-family: VanguardCF-Medium;
            font-size: 2.5vw;
            margin: 0;
            transform: translateY(-0.2ch);
            white-space: nowrap;

            @include devicesMax(mobile) {
              font-size: 7vw;
            }
          }
        }

        .bg {
          @include position(absolute, 25%, auto, auto, 5%);
          @include dims(100%);
          border-radius: 14px;
          background: #000;
          z-index: -1;

          @include devicesMax(mobile) {
            @include position(absolute, 20%, auto, auto, 3%);
            border-radius: 10px;
          }
        }
      }

      .smileyContainer {
        @include position(absolute, 0, auto, auto, 50%);
        @include dims(50vw, auto);
        transform: translate(-70%, 20%);

        @include devicesMax(tablet) {
          transform: translate(-60%, 30%);
        }

        @include devicesMax(mobile) {
          @include dims(90vw, auto);
          transform: translate(-55%, 30%);
        }

        .jdSmiley {
          @include dims(100%);
          display: block;
          object-fit: contain;
          pointer-events: none;
        }
      }

      .arrowContainer {
        @include position(absolute, 7%, auto, auto, 57%);
        @include dims(10vw, auto);
        transform: rotate(10deg);

        @include devicesMax(mobile) {
          @include position(absolute, 40%, auto, auto, 65%);
          @include dims(20vw, auto);
          transform: rotate(-10deg);
        }

        .arrow {
          @include dims(100%);
          display: block;
          object-fit: contain;
        }
      }
    }
  }
}

.modalContent {
  @include dims(75vw, auto);
  position: relative;
  top: 50vh;
  transform: translateY(-50%);

  @extend .flexCenterX;

  @include devicesMax(tablet) {
    @include dims(90vw, auto);
  }

  @include devicesMax(mobile) {
    @include dims(100vw, auto);
  }
}
