@import "assets/scss/utils.scss";

.mediaContainer {
  padding: 0.75vw 8vw;

  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 1.5vw;

  @include devicesMax(tabletMini) {
    flex-wrap: wrap;
  }

  @include devicesMax(mobile) {
    padding: 2vw 4vw;
    gap: 4vw;
  }

  &.paddingX {
    padding: 0.75vw 20vw;

    @include devicesMax(tablet) {
      padding: 0.75vw 8vw;
    }

    @include devicesMax(mobile) {
      padding: 2vw 4vw;
    }
  }

  .mediaWrapper {
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    z-index: 20;
    @extend .flexCenterX;
    @extend .cursorPointer;

    @include devicesMax(tabletMini) {
      flex-basis: 100%;
      width: 100%;
    }

    @include devicesMin(aboveTablet) {
      &:hover {
        .thumbnail {
          transform: scale(1.1);
        }

        .iconWrapper {
          @include dims(5vw);
        }

        .sliderBtn {
          visibility: visible;
          opacity: 1;
        }
      }
    }

    .vidC {
      @include dims(100%);
    }

    .videoCover {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: orangered;
      z-index: 100;
      width: 100%;
      height: 100%;
    }

    .video {
      @include dims(100%);
      object-fit: cover;
      z-index: 10;
    }

    .iconWrapper {
      @include position(absolute, 50%, 0, 0, 50%);
      @include dims(7vw);
      background: #d9ff51;
      border-radius: 50%;
      transform: translate3d(-50%, -50%, 20px);
      transition: 0.3s all ease-in-out;

      pointer-events: none;
      z-index: 30;

      @extend .flexCenterX;

      @include devicesMax(mobile) {
        @include dims(15vw);
      }

      &.hidden {
        @extend .hide;
      }

      .play {
        @include dims(2vw);
        transform: translateX(10%);

        @include devicesMax(mobile) {
          @include dims(5vw);
        }
      }
    }

    .thumbnail {
      @include position(absolute, 0, 0, 0, 0);
      @include dims(100%);
      object-fit: cover;
      transition: 0.4s all ease-in-out;
      z-index: 20;
      transform: translateZ(10px);
      @extend .cursorPointer;

      &.hidden {
        @extend .hide;
      }
    }

    .sliderBtn {
      @include position(absolute, 0, 0, auto, auto);
      @extend .flexCenterX;
      border-radius: 8px;
      border: 1px solid #000;
      margin: 1rem;
      padding: 1rem 2rem;
      z-index: 30;
      transition: 0.4s all ease;
      @extend .hide;

      background: #d9ff51;
      font-size: 1.5vw;
      font-family: VanguardCF-Bold;

      @include devicesMax(tablet) {
        background: transparent;
        padding: 0.5rem 1.25rem;
        visibility: visible;
        opacity: 1;
        font-size: 2vw;
        display: none;
      }

      @include devicesMax(mobile) {
        padding: 0.25rem 1rem;
        font-size: 4.5vw;
      }
    }
  }
}

.sliderWrapper {
  @include dims(100vw, 100vh);
  @extend .flexCenterX;
}
