@import "assets/scss/utils.scss";

.workDetail {
  background: #f2f2f2;
  border-bottom-left-radius: 32px;
  border-bottom-right-radius: 32px;
  padding-bottom: 10vw;

  min-height: 100vh;

  .marqueeContainer {
    position: relative;
    padding: 7vw 0;
    width: 100%;

    @include devicesMax(mobile) {
      margin: 10vw 0 0 0;
    }

    .mWrapper {
      transform: translateX(-15%);
      .spanWrapper {
        display: inline-block;

        span {
          font-family: VanguardCF-ExtraBold;
          font-size: 18vw;
          line-height: 100%;
          color: #000000;

          @include devicesMax(mobile) {
            font-size: 30vw;
          }
        }

        .rightMargin {
          margin-right: 2.5rem;

          @include devicesMax(mobile) {
            margin-right: 1.5rem;
          }
        }

        .inlineO {
          font-family: Ageya-Oblique;
          font-weight: 100;
          margin-left: -2.5vw;
          margin-right: 0;

          @include devicesMax(mobile) {
            margin-left: -4vw;
          }
        }
      }
    }

    .otherCoolStuff {
      @include position(absolute, auto, 15vw, 3vw, auto);
      width: 12vw;
      z-index: 10;

      @include devicesMax(tabletMini) {
        width: 15vw;
      }

      @include devicesMax(mobile) {
        width: 25vw;
      }
    }
  }
}
