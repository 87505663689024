@import "./utils.scss";

.projectCard {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  padding-bottom: 5vw;
  @extend .cursorPointer;

  @include devicesMax(mobile) {
    margin-bottom: 10vw;
  }

  .imgContainer {
    @include dims(100%, 30vw);
    background: #dedede;
    border-radius: 20px;
    overflow: hidden;
    position: relative;

    @include devicesMax(tabletMini) {
      @include dims(100%, 45vw);
    }

    @include devicesMax(mobile) {
      @include dims(100%, 70vw);
      margin-bottom: 2vw;
    }

    .award {
      position: absolute;
      right: 1vw;
      bottom: 1vw;
      width: 12vw;
      z-index: 100;
      border-radius: none;

      @include devicesMax(mobile) {
        right: 3vw;
        bottom: 3vw;
        width: 30vw;
      }
    }

    .projectImg,
    .projectVid {
      @include dims(100%);
      display: block;
      object-fit: cover;
      object-position: center;
    }
  }

  .cardTitle {
    color: #000000;
    font-family: VanguardCF-DemiBold;
    font-size: 4vw;
    line-height: 100%;
    letter-spacing: 0px;
    margin: 1vw 0;
    text-align: left;

    @include devicesMax(tablet) {
      font-size: 4.5vw;
    }

    @include devicesMax(mobile) {
      font-size: 15vw;
      margin: 0 0 4vw 0;
    }
  }

  .cardTagWrapper {
    @extend .flexStart;
    flex-wrap: wrap;

    .cardTag {
      border: 1px solid #787878;
      border-radius: 32px;
      color: #000000;
      font-family: VisbyCF-DemiBold;
      font-size: 12px;
      line-height: 100%;
      margin: 0.5vw 0.25vw 0.5vw 0;
      padding: 0.5rem 1rem;
      text-transform: uppercase;
      text-align: left;

      @include devicesMax(tablet) {
        font-size: 10px;
        padding: 0.25rem 0.75rem;
      }

      @include devicesMax(mobile) {
        margin: 1vw 1vw 1vw 0;
        font-size: 12px;
        font-family: VisbyCF-Medium;
      }

      .seperator {
        padding: 0 0.5rem;
        font-family: VanguardCF-Light;
      }
    }
  }
}
