@import "assets/scss/utils.scss";

.notFoundPage {
  @include dims(100%);
  @extend .flexCenterY;

  img {
    margin: 4vw 0;

    @include devicesMax(tablet) {
      margin-top: 20vw;
      width: 70vw;
    }
  }

  h1 {
    font-size: 3rem;
    font-family: VisbyCF-DemiBold;
    color: #ffffff;
    text-align: center;

    @include devicesMax(mobile) {
      font-size: 2rem;
    }
  }

  p {
    font-size: 18px;
    font-family: VisbyCF-Medium;
    color: #ffffff;
    text-align: center;

    @include devicesMax(mobile) {
      font-size: 14px;
      margin-top: 4vw;
      width: 90vw;
    }
  }
}
