@import "assets/scss/variables.scss";
@import "assets/scss/utils.scss";
@import "assets/scss/PrivacyAndCookieModal.module.scss";

.contact {
  .intro {
    @include dims(100%, calc(100vh - $headerHeight));
    padding-bottom: 15vw;
    position: relative;
    @extend .flexCenterY;

    @include devicesMax(tablet) {
      @include dims(100%, auto);
    }

    @include devicesMax(mobile) {
      margin-top: 10vw;
    }

    .line {
      margin-bottom: -10px;

      &:nth-child(1) {
        margin-right: 17.5vw;

        @include devicesMax(mobile) {
          margin: 0;
        }
      }

      &:nth-child(2) {
        margin-left: 22.5vw;

        @include devicesMax(mobile) {
          margin: 0;
        }
      }

      &:nth-child(3) {
        margin-top: -15px;
        margin-left: 2.5vw;

        @include devicesMax(mobile) {
          margin: 0;
        }

        .text {
          font-family: Ageya-Oblique;
        }
      }

      .text {
        color: #f8f9fa;
        font-size: 9vw;
        font-family: VanguardCF-ExtraBold;
        line-height: 100%;

        br {
          display: none;
        }

        @include devicesMax(tablet) {
          font-size: 11vw;
        }

        @include devicesMax(mobile) {
          font-size: 20vw;
          text-align: center;

          br {
            display: block;
          }
        }
      }

      .inlineLove {
        position: relative;
      }

      .inlineLoveBlack,
      .inlineLoveGreen {
        @include position(absolute, 50%, auto, auto, 50%);
        transform: translate(-50%, -50%);
      }

      .inlineLoveBlack {
        color: #000000;
        z-index: -1;
        margin-top: 4px;
        margin-left: -16px;

        @include devicesMax(mobile) {
          margin-top: 2px;
          margin-left: -4px;
        }
      }

      .inlineLoveGreen {
        color: #d9ff51;
        z-index: -2;
        margin-top: 5px;
        margin-left: -19px;

        @include devicesMax(mobile) {
          margin-top: 3px;
          margin-left: -6px;
        }
      }
    }

    .contactBlob {
      @include position(absolute, auto, 0, 10%, auto);
      @include dims(65vw, auto);

      @include devicesMax(mobile) {
        @include position(absolute, -10vw, -50vw, auto, auto);
        @include dims(150vw, auto);
      }
    }

    .contactSmile {
      @include position(absolute, -5%, 15%, auto, auto);
      @include dims(12vw);

      @include devicesMax(mobile) {
        @include position(absolute, 25vw, auto, auto, 2vw);
        @include dims(20vw);
        transform: rotate(-10deg);
      }
    }
  }
}

.contactForm {
  background: #000000;
  border: 2px solid #f8f9fa;
  border-radius: 64px;
  margin: 0 2vw;
  padding: 12vw 13vw;
  position: relative;

  @include devicesMax(mobile) {
    margin: 70vw 0 0 0;
    padding: 50vw 4vw 15vw 4vw;
    border-radius: 32px;
  }

  .dropALine {
    @include position(absolute, -12vw, auto, auto, 5vw);
    @include dims(15vw, auto);

    @include devicesMax(mobile) {
      @include position(absolute, -200px, auto, auto, 50%);
      @include dims(65vw, auto);
      transform: translateX(-50%);
    }
  }

  .form {
    @include dims(100%);
    display: flex;
    flex-direction: column;

    .rowX {
      margin-bottom: 4vw;
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 2vw;

      @include devicesMax(mobile) {
        align-items: center;
        flex-direction: column;
        justify-content: stretch;
        margin-bottom: 25vw;
      }
    }

    .rowY {
      margin-bottom: 3vw;
      display: flex;
      flex-direction: column;

      @include devicesMax(mobile) {
        gap: 10vw;
        margin-bottom: 25vw;
      }
    }

    .formText {
      color: #f8f9fa;
      font-family: VanguardCF-Bold;
      font-size: 4.8vw;
      line-height: 100%;
      letter-spacing: 0;
      flex-shrink: 0;
      margin: 0;

      br {
        display: none;

        @include devicesMax(mobile) {
          display: block;
        }
      }

      @include devicesMax(mobile) {
        text-align: center;
        font-size: 13.5vw;
        line-height: 120%;
      }
    }

    .inputWrapper {
      @include dims(100%, 4.5vw);
      flex-grow: 1;
      position: relative;

      @include devicesMax(mobile) {
        @include dims(100%, 12vw);
        margin-top: 4vw;
      }

      &.textarea {
        @include dims(100%, 9vw);

        @include devicesMax(mobile) {
          @include dims(100%, 30vw);
          font-size: 6vw;
        }
      }

      &::after {
        content: "";
        @include position(absolute, 110%, 0, 0, 0);
        @include dims(100%, 1px);
        background: #343434;
      }

      .label {
        @include position(absolute, 0, 0, 0, 0);
        @include dims(100%);
        color: #343434;
        font-family: VanguardCF-Bold;
        font-size: 4.8vw;
        line-height: 100%;
        opacity: 0.7;
        pointer-events: none;
        text-align: center;
        transition: 0.2s all ease;
        white-space: nowrap;

        @include devicesMax(mobile) {
          font-size: 12vw;
        }

        &.hidden {
          @extend .hide;
        }
      }

      .formInput {
        @include dims(100%);
        background: transparent;
        border: none;
        color: #f8f9fa;
        font-family: VisbyCF-Heavy;
        font-size: 3vw;
        line-height: 100%;
        outline: none;
        text-align: center;
        vertical-align: bottom;
        transform: translateY(0.3vw);

        @include devicesMax(mobile) {
          font-size: 7vw;
          min-height: 30px;
        }

        &.textarea {
          color: #f8f9fa;
          font-family: VisbyCF-Heavy;
          font-size: 1.75vw;
          resize: none;
          line-height: 140%;

          @include devicesMax(mobile) {
            font-size: 7vw;
          }
        }
      }
    }

    .buttons {
      @extend .flexStartX;
      column-gap: 1rem;
      margin-top: 2.5vw;

      @include devicesMax(mobile) {
        justify-content: center;
        flex-direction: column;
        row-gap: 1rem;
      }

      .reasonBtn {
        background: transparent;
        box-shadow: 0 0 0 1pt #f2f2f2;
        border-radius: 33px;
        font-size: 2vw;
        padding: 0.25vw 2.5vw;
        @extend .flexCenterX;
        @extend .cursorPointer;

        &.active {
          background: #d9ff51;
          box-shadow: 0 0 0 1pt #d9ff51;

          .btnText {
            color: #000000;
          }
        }

        @include devicesMax(mobile) {
          padding: 1vw 10vw;
          font-size: 7vw;
          border-radius: 28px;
        }

        .btnText {
          color: #f8f9fa;
          font-family: VanguardCF-Regular;
          font-weight: 400;
          transform: translateY(-0.05em);
        }

        @include devicesMin(tablet) {
          &:hover {
            background: #d9ff51;
            box-shadow: 0 0 0 1pt #d9ff51;
            outline: none;

            .btnText {
              color: #000000;
            }
          }
        }
      }
    }

    .privacyPolicy {
      @extend .flexStartX;
      column-gap: 1rem;

      @include devicesMax(mobile) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 7vw;
      }

      &.active {
        .btnContainer {
          .accept {
            opacity: 1;
            transform: scale(0.95);
          }
        }

        .submitBtn {
          opacity: 1;
          padding-top: 0.25rem;
          padding-bottom: 0.25rem;
          pointer-events: auto;
        }

        .submitBtnMobile {
          opacity: 1;
          pointer-events: auto;
        }
      }

      .btnContainer {
        @include dims(7vw);
        flex-shrink: 0;
        border-radius: 50%;
        border: 1px solid #d9ff51;
        padding: 0.75rem;
        position: relative;
        @extend .cursorPointer;

        @include devicesMax(mobile) {
          @include dims(30vw);
        }

        .accept {
          opacity: 0.2;
          transition: 0.2s all ease;
          transform: scale(0.8);
          transform-origin: center;
          user-select: none;
          @extend .absoluteStretch;
        }
      }

      .privacyText {
        color: #f2f2f2;
        font-family: VisbyCF-Medium;
        font-size: 1.2vw;
        line-height: 100%;
        text-align: left;
        @extend .cursorPointer;

        @include devicesMax(mobile) {
          font-size: 4vw;
          text-align: center;
          line-height: 160%;
        }

        .link {
          text-decoration: underline;
        }

        br {
          display: none;

          @include devicesMax(mobile) {
            display: block;
          }
        }
      }

      .submitBtn {
        background: transparent;
        box-shadow: 0 0 0 1pt #f2f2f2;
        border: none;
        border-radius: 32px;
        margin-left: auto;
        opacity: 0;
        outline: none;
        padding: 0.5rem 9vw;
        pointer-events: none;
        text-decoration: none;
        transition: 0.3s all ease;
        @extend .flexCenterX;

        @include devicesMax(tabletMini) {
          display: none;
        }

        .btnText {
          color: #f8f9fa;
          font-size: 2.5vw;
          font-family: VanguardCF-Regular;
          transform: translateY(-0.1em);

          @include devicesMax(mobile) {
            font-size: 24px;
          }
        }

        @include devicesMin(aboveTablet) {
          &:hover {
            background: #d9ff51;
            box-shadow: 0 0 0 1pt #d9ff51;
            outline: none;

            .btnText {
              color: #000000;
            }
          }
        }
      }

      .submitBtnMobile {
        display: none;

        @include devicesMax(mobile) {
          background: transparent;
          border: none;
          opacity: 0;
          pointer-events: none;
          transition: 0.2s all ease;

          display: flex;
          align-items: center;
          justify-content: center;
          column-gap: 1rem;
        }

        .btnText {
          color: #f2f2f2;
          font-family: VanguardCF-Medium;
          font-size: 14vw;
          line-height: 100%;
          text-align: left;
          transform: translateY(-0.05em);
        }

        .iconRightArrow {
          @include dims(13vw);
          background: #d9ff51;
          border-radius: 50%;
          transform: rotate(45deg);
          @extend .flexCenterX;

          @include devicesMax(mobile) {
            height: 16vw;
            width: 16vw;
          }
        }
      }
    }
  }
}

.privacyAndCookieModal {
  @include dims(80%, auto);
  color: #ffffff;
  margin: 10vw auto;
  overflow-x: hidden;

  h5 {
    font-family: VisbyCF-DemiBold;
    font-size: 2rem;
    margin-bottom: 2vw;
  }

  p {
    font-family: VisbyCF-Regular;
    font-size: 1rem;
    line-height: 160%;
    margin-bottom: 1.5vw;
  }

  a {
    text-decoration: underline;
  }

  small {
    display: block;
    font-family: VisbyCF-Regular;
    font-style: italic;
    margin-top: 4rem;
  }
}

.contactSuccess {
  @include dims(40vw, 35vw);
  background: #f2f2f2;
  border: 10px solid #d9ff51;
  border-radius: 32px;
  padding: 0 6vw 1vw 6vw;
  position: relative;
  @extend .flexCenterY;

  @include devicesMax(tablet) {
    @include dims(55vw, 45vw);
    padding: 0 8vw 2vw 8vw;
  }

  @include devicesMax(mobile) {
    @include dims(90vw, auto);
    border: 3px solid #d9ff51;
    padding: 0 0 4vw 0;
  }

  .welcomeAboard {
    @include dims(22vw, auto);
    margin-bottom: -5.5vw;
    z-index: 10;

    @include devicesMax(tablet) {
      @include dims(32vw, auto);
      margin-bottom: -9vw;
    }

    @include devicesMax(mobile) {
      @include dims(50vw, auto);
      margin-bottom: -15vw;
    }
  }

  .title {
    color: #000000;
    font-family: VanguardCF-Bold;
    font-size: 4vw;
    margin-bottom: 1vw;
    text-align: center;

    @include devicesMax(tablet) {
      font-size: 5vw;
    }

    @include devicesMax(mobile) {
      font-size: 10vw;
      margin-bottom: 4vw;
    }
  }

  .text {
    color: #000000;
    font-family: VisbyCF-Medium;
    font-size: 1.5vw;
    margin-bottom: 2vw;
    text-align: center;

    @include devicesMax(tablet) {
      font-size: 1.75vw;
    }

    @include devicesMax(mobile) {
      font-size: 3.5vw;
      margin-bottom: 6vw;
    }
  }

  .doneButton {
    border: 1px solid #000;
    border-radius: 32px;
    color: #000000;
    font-family: VanguardCF-Medium;
    font-size: 2.25vw;
    padding: 0.25rem 6rem 0.5rem 6rem;
    @extend .cursorPointer;

    @include devicesMin(aboveTablet) {
      &:hover {
        background: #d9ff51;
      }
    }

    @include devicesMax(tablet) {
      font-size: 3vw;
    }

    @include devicesMax(mobile) {
      font-size: 7vw;
      padding: 0.15rem 4rem 0.25rem 4rem;
    }
  }
}

.contactSuccess {
  background: #f2f2f2;
  border: 10px solid #d9ff51;
  border-radius: 32px;
  margin-top: 10vw;
  padding: 0 3vw 1vw 3vw;
  position: relative;
  @extend .flexCenterY;

  @include devicesMax(tablet) {
    padding: 0 8vw 2vw 8vw;
    margin-top: 45vw;
  }

  @include devicesMax(mobile) {
    @include dims(90vw, auto);
    border: 3px solid #d9ff51;
    padding: 0 8vw 4vw 8vw;
  }

  .welcomeAboard {
    @include dims(22vw, auto);
    margin-top: -5.5vw;
    padding-bottom: 7vw;
    z-index: 10;

    @include devicesMax(tablet) {
      @include dims(32vw, auto);
      margin-top: -10vw;
      padding-bottom: 10vw;
    }

    @include devicesMax(mobile) {
      @include dims(50vw, auto);
      margin-top: -15vw;
      padding-bottom: 17vw;
    }
  }

  .title {
    color: #000000;
    font-family: VanguardCF-Bold;
    font-size: 4vw;
    margin-bottom: 1vw;
    text-align: center;

    @include devicesMax(tablet) {
      font-size: 5vw;
    }

    @include devicesMax(mobile) {
      font-size: 10vw;
      margin-bottom: 4vw;
    }
  }

  .text {
    color: #000000;
    font-family: VisbyCF-Medium;
    font-size: 1.5vw;
    margin-bottom: 2vw;
    text-align: center;

    @include devicesMax(tablet) {
      font-size: 1.75vw;
    }

    @include devicesMax(mobile) {
      font-size: 3.5vw;
      margin-bottom: 6vw;
    }
  }

  .doneButton {
    border: 1px solid #000;
    border-radius: 32px;
    color: #000000;
    font-family: VanguardCF-Medium;
    font-size: 2.25vw;
    padding: 0.25rem 6rem 0.5rem 6rem;
    @extend .cursorPointer;

    @include devicesMin(aboveTablet) {
      &:hover {
        background: #d9ff51;
      }
    }

    @include devicesMax(tablet) {
      font-size: 3vw;
    }

    @include devicesMax(mobile) {
      font-size: 7vw;
      padding: 0.15rem 4rem 0.25rem 4rem;
    }
  }
}
