@import "assets/scss/utils.scss";

.absoluteFullPage {
  @include position(absolute, 0, 0, 0, 0);
  @include dims(100vw, 100vh);
}

.oHiddenWrapper {
  @include position(fixed, 0, 0, 0, 0);
  pointer-events: none;
  z-index: z-index("menuDummy");

  .oHidden {
    overflow: hidden;
    position: absolute;

    &:nth-child(1) {
      z-index: 3000;
      // border: 2px solid red;
    }

    &:nth-child(2) {
      left: 33vw;
      z-index: 2000;
      //border: 2px solid blue;
    }

    &:nth-child(3) {
      left: 66vw;
      z-index: 1000;
      //border: 2px solid pink;
    }
  }
}

.navMenu {
  background: #d9ff51;
  z-index: z-index("menu");

  @extend .flexEndX;

  @include devicesMax(tablet) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  &.real {
    @include position(fixed, 0, 0, 0, 0);
    @include dims(100vw, 100vh);
  }

  &.dummy {
    @include dims(100vw, 100vh);
  }

  .jdfxLogoMenu {
    position: absolute;
    left: 5vw;
    top: 2.5vw;
    width: 25vw;
    z-index: 999;

    @include devicesMax(tablet) {
      left: 2.5vw;
      top: 1.5vw;
      width: 25vw;
    }

    @include devicesMax(mobile) {
      width: 27vw;
      top: 3vw;
      left: 3vw;
    }
  }

  .linksWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-right: 6vw;

    @include devicesMax(tablet) {
      padding: 20vw 0 0 0;
      margin: 0 auto;
      align-items: center;
    }

    @include devicesMax(tabletMini) {
      margin: 0 auto;
      align-items: center;
    }

    .chooseWisely {
      display: none;

      @include devicesMax(tablet) {
        display: block;
        width: 20vw;
        padding-top: 5vw;
      }

      @include devicesMax(mobile) {
        width: 35vw;
      }
    }
  }

  .navMenuItem {
    cursor: pointer;
    user-select: none;
    display: flex;
    padding: 16px 0;

    @include devicesMax(tabletMini) {
      padding: 12px 0;
    }

    img {
      cursor: pointer;
      pointer-events: none;
      will-change: transform;

      @include devicesMax(tabletMini) {
        height: 18vw;
      }
    }
  }
}
