@import "assets/scss/utils.scss";

.workInfo {
  background: #f2f2f2;
  margin-top: -3vw;
  padding: 0 2vw 4.5vw 2vw;

  @include devicesMax(tablet) {
    margin-top: -5vw;
  }

  @include devicesMax(mobile) {
    margin-top: -10vw;
  }

  .mediaWrapper {
    background: #dedede;
    border-radius: 16px;
    overflow: hidden;
    position: relative;
    width: 100%;
    z-index: 50;
    height: 890px;

    @include devicesMax(tablet) {
      height: 42vh;
    }

    @include devicesMax(mobile) {
      border-radius: 8px;
    }

    .projectVid {
      @include dims(100%);
      display: block;
      object-fit: cover;
      transition: opacity 400ms ease 0ms;

      @include devicesMax(mobile) {
        border-radius: 16px;
      }
    }

    .award {
      position: absolute;
      right: 2vw;
      bottom: 2vw;
      width: 15vw;
      z-index: 100;
      border-radius: none;

      @include devicesMax(mobile) {
        right: 3vw;
        bottom: 3vw;
        width: 30vw;
      }
    }
  }

  .details {
    position: relative;
    padding: 0 1.5vw;
    z-index: 5;

    .title {
      padding: 3vw 0;
      border-bottom: 2px solid #000000;
      z-index: 50;

      @include devicesMax(mobile) {
        padding: 10vw 0;
      }

      small {
        display: block;
        font-family: VanguardCF-DemiBold;
        font-size: 1.5vw;
        line-height: 100%;

        @include devicesMax(mobile) {
          font-size: 7vw;
        }
      }

      h1 {
        display: inline-block;
        font-size: 5vw;
        line-height: 100%;
        margin: 0;

        @include devicesMax(mobile) {
          font-size: 15vw;
        }
      }

      .company {
        font-family: VanguardCF-Bold;

        br {
          display: none;
        }

        @include devicesMax(mobile) {
          br {
            display: block;
          }
        }
      }

      .service {
        font-family: VanguardCF-Medium;
      }

      .seperator {
        font-family: VanguardCF-Medium;
        padding: 0 1vw;
      }
    }

    .text {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding: 3vw 0 7vw 0;

      @include devicesMax(mobile) {
        padding: 10vw 0;
        flex-direction: column;
        gap: 15vw;
      }

      .left {
        flex-basis: 45%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);

        @include devicesMax(mobile) {
          grid-template-columns: repeat(1, 1fr);
        }

        .col {
          flex-direction: column;
          margin-right: 4vw;

          &:last-child {
            margin-right: 0;
          }

          @include devicesMax(mobile) {
            margin-bottom: 0vw;
          }

          .item {
            color: #000;
            font-family: VisbyCF-Regular;
            font-size: 1.1vw;
            line-height: 130%;
            margin-bottom: 3vw;

            @include devicesMax(mobile) {
              font-size: 5vw;
              margin-bottom: 7vw;
            }

            .infoTitle {
              font-family: VanguardCF-DemiBold;
              font-size: 1.5vw;
              line-height: 100%;
              margin: 0;
              margin-bottom: 1vw;
              position: relative;
              white-space: nowrap;

              @include devicesMax(mobile) {
                font-size: 7vw;
                margin-bottom: 5vw;
              }

              .trophy {
                height: 2vw;
                width: 2vw;

                position: absolute;
                top: 50%;
                transform: translateY(-50%) translateX(30%);

                @include devicesMax(mobile) {
                  height: 7vw;
                  width: 7vw;
                }
              }
            }

            .par {
              font-family: VisbyCF-Regular;
              font-size: 1.1vw;
              line-height: 100%;
              margin: 0;
              margin-bottom: 1vw;
              white-space: nowrap;

              @include devicesMax(mobile) {
                font-size: 5vw;
                margin-bottom: 5vw;
              }

              &.awards {
                margin-bottom: 0.5vw;
                color: #000;
                display: block;
                text-decoration: underline;

                @include devicesMax(mobile) {
                  margin-bottom: 2vw;
                }
              }

              &.link {
                color: #000;
                text-decoration: underline;
              }
            }
          }
        }
      }

      .description {
        transform: translateY(-1vw);
        flex-basis: 40%;

        .descTitle {
          font-family: VanguardCF-Bold;
          font-size: 5vw;
          line-height: 100%;
          margin: 0 0 2vw 0;

          @include devicesMax(mobile) {
            font-size: 18vw;
            margin: 0 0 12vw 0;
          }
        }

        .descTextWrapper {
          overflow: hidden;
          transition: 0.5s all ease-in-out;
          position: relative;

          @include devicesMax(mobile) {
            // max-height: 63vw;

            &::before,
            &::after {
              content: "";
              position: absolute;
              bottom: -5px;
              left: 0;
              right: 0;
              width: 100%;
              height: 30%;
              background: linear-gradient(0deg, #f2f2f2 30%, rgba(255, 255, 255, 0));

              transition: 0.4s all ease;
            }

            &.expanded {
              // max-height: 200vw;

              &::after,
              &::before {
                opacity: 0;
                visibility: hidden;
              }
            }
          }

          .descText {
            font-family: VisbyCF-Regular;
            font-size: 1.1vw;
            line-height: 200%;
            margin: 0 0 1vw 0;

            @include devicesMax(mobile) {
              font-size: 5vw;
              width: 100%;
              margin: 0 0 5vw 0;
            }

            p {
              margin-bottom: 1vw;
            }
          }
        }

        .press {
          p {
            font-family: VisbyCF-Regular;
            font-size: 1.1vw;
            padding: 0.5vw 0;

            @include devicesMax(mobile) {
              font-size: 5vw;
            }

            a {
              background: #d9ff51;
              color: #000;
              font-family: VisbyCF-DemiBold;
              font-style: italic;
              padding: 0.5vw 0.5vw;
            }
          }
        }

        .btnReadMore {
          background: transparent;
          box-shadow: 0 0 0 1pt #787878;
          border-radius: 28px;
          border: none;
          cursor: none;
          outline: none;
          padding: 0.5rem 1rem;
          text-decoration: none;
          @extend .flexCenterX;

          @include devicesMin(aboveTablet) {
            &:hover {
              background: #d9ff51;
              outline: none;

              .btnText {
                color: #000000;
              }
            }
          }

          @include devicesMax(tablet) {
            padding: 0.25rem 0.75rem;
          }

          @include devicesMax(mobile) {
            display: none;
          }

          .btnText {
            color: #000;
            cursor: none;
            font-family: VisbyCF-Medium;
            font-size: 14px;
            line-height: 100%;
            margin: 0;
            transform: translateY(0.1ch);
            white-space: nowrap;

            @include devicesMax(tablet) {
              font-size: 12px;
            }

            @include devicesMax(mobile) {
              font-size: 4vw;
            }
          }

          .arrowW {
            margin-left: 0.5rem;

            * {
              path {
                fill: #000;
              }
            }
          }
        }

        .viewMoreBtnTouch {
          border: none;
          background: transparent;
          padding: 0;

          @extend .flexCenterX;
          gap: 0.5rem;

          @include devicesMax(mobile) {
            margin-top: 5vw;
          }

          p {
            text-align: left;
            font-family: VanguardCF-Medium;
            font-size: 2.5vw;
            line-height: 100%;
            letter-spacing: 0px;
            color: #000000;
            margin: 0;
            transform: translateY(-2px);

            @include devicesMax(mobile) {
              font-size: 8vw;
            }
          }

          .iconRightArrow {
            @include dims(3.5vw);
            position: relative;
            background: #d9ff51;
            border-radius: 50%;

            display: flex;
            align-items: center;
            justify-content: center;

            @include devicesMax(mobile) {
              height: 10vw;
              width: 10vw;
            }

            .arrow {
              position: absolute;

              @include devicesMax(tablet) {
                height: 35%;
                width: 35%;
              }
            }
          }
        }
      }
    }
  }
}

.modalContent {
  @include dims(100vw, 100vh);
  background: #f2f2f2;
  overflow: scroll;
  padding: 9vw 5vw;

  display: flex;
  align-items: flex-start;
  justify-content: flex-end;

  @include devicesMax(tablet) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    // padding: 15vw 7.5vw 0vw 7.5vw;
  }

  .imgW {
    @include position(fixed, 10vw, auto, auto, 27%);
    @include dims(40vw, 22.5vw);
    border-radius: 1rem;
    flex-shrink: 0;
    transform: translateX(-50%);
    overflow: hidden;

    @include devicesMax(tablet) {
      @include position(relative, auto, auto, auto, auto);
      @include dims(100%, 50vw);
      transform: translate(0, 0);
      margin-bottom: 5vw;
    }

    @include devicesMax(mobile) {
      @include dims(100%, 100vw);
      margin-bottom: 5vw;
    }

    .img,
    .projectVid {
      @include dims(100%);
      display: block;
      object-fit: cover;
    }
  }

  .info {
    margin-right: 5vw;

    @include devicesMax(tablet) {
      margin-top: 0;
      margin-right: 0;
    }

    .descTitle {
      font-family: VanguardCF-Bold;
      font-size: 5vw;
      line-height: 100%;
      margin: 0 0 5vw 0;

      @include devicesMax(tablet) {
        font-size: 10vw;
      }

      @include devicesMax(mobile) {
        font-size: 18vw;
        margin: 0 0 12vw 0;
      }
    }

    .descText {
      p {
        font-family: VisbyCF-Regular;
        font-size: 1.1vw;
        line-height: 200%;
        margin: 0 0 1vw 0;
        width: 35vw;

        @include devicesMax(tablet) {
          font-size: 2.5vw;
          width: 100%;
        }

        @include devicesMax(mobile) {
          font-size: 7vw;
          width: 100%;
          margin: 0 0 5vw 0;
        }
      }
    }
  }
}
