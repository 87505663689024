@import "assets/scss/utils.scss";

.mediaSlider {
  @include dims(100vw, auto);
  margin: 0.75vw 0;
  position: relative;

  @include devicesMax(mobile) {
    margin: 1.5vw 0;
  }

  .mediaWrapper {
    @extend .flexCenterX;
    padding: 0.75vw 8vw;

    @include devicesMax(mobile) {
      padding: 0;
    }

    .img,
    .video {
      @include dims(100%);
      border-radius: 8px;
      display: block;
      object-fit: cover;
      overflow: hidden;

      @include devicesMax(mobile) {
        border-radius: 0;
      }
    }
  }

  .btnWrapper {
    cursor: none;
    display: flex;
    height: fit-content;
    transition: 0.3s all ease;
    width: 120px;
    z-index: 10;

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    @extend .show;

    @include devicesMax(mobile) {
      // transform: translateY(150%);
      display: none;
    }

    &.disabled {
      @extend .hide;
    }

    &.btnPrev {
      justify-content: flex-start;
      left: 9vw;

      @include devicesMax(tabletMini) {
        left: 4vw;
      }

      @include devicesMax(mobile) {
        left: 5vw;
      }
    }

    &.btnNext {
      justify-content: flex-end;
      right: 9vw;

      @include devicesMax(mobile) {
        right: 5vw;
      }
    }

    .btnInner {
      @include dims(90px, 50px);
      background-color: #f2f2f2;
      border: 0.5px solid #000000;
      border-radius: 32px;
      cursor: none;
      position: relative;
      transition: 0.3s all ease;
      @extend .flexCenterX;

      @include devicesMax(tablet) {
        @include dims(50px);
        border-radius: 50%;
      }

      @include devicesMax(mobile) {
        @include dims(40px);
      }

      .btnArrow {
        cursor: none;
        pointer-events: none;

        &.next {
          transform: rotate(45deg);
        }

        &.prev {
          transform: rotate(-135deg);
        }
      }
    }

    @include devicesMin(aboveTablet) {
      &:hover {
        .btnInner {
          @include dims(50px);
          background: #d9ff51;
          border: 1px solid #d9ff51;
          cursor: none;
        }
      }
    }
  }
}
