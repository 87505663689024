@import "assets/scss/utils.scss";

.revealWrapper {
  position: relative;
  will-change: transform;
  z-index: z-index("footer");

  .overlay {
    @include position(absolute, 0, 0, 0, 0);
    @include dims(100%);
    background: #000000;
    pointer-events: none;
    z-index: z-index("footerOverlay");

    @include devicesMax(tablet) {
      display: none;
    }
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;

    @include devicesMax(mobile) {
      justify-content: center;
      margin-top: 0;
    }

    @include devicesMax(mobile) {
      width: fit-content;
      flex-direction: column;
      margin-left: auto;
      margin-right: auto;
    }

    .btn {
      margin-top: 2vw;
      width: 12vw;
      padding: 0.65rem 0;
      background: transparent;
      box-shadow: 0 0 0 1pt #ffffff75;
      outline: none;
      border-radius: 28px;
      text-decoration: none;
      cursor: none;

      @include devicesMin(aboveTablet) {
        &:hover {
          background: #d9ff51;
          box-shadow: 0 0 0 1pt #d9ff51;
          outline: none;

          .btnText {
            color: #000000;
          }
        }
      }

      @extend .flexCenterX;

      @include devicesMax(tablet) {
        padding: 0.5rem 0;
      }

      @include devicesMax(mobile) {
        width: 22vw;
      }

      @include devicesMax(mobile) {
        width: 50vw;
      }

      .btnText {
        color: #f8f9fa;
        font-family: VisbyCF-Medium;
        font-size: 1.25vw;
        line-height: 100%;
        margin: 0;
        white-space: nowrap;
        cursor: none;

        @include devicesMax(mobile) {
          font-size: 2.5vw;
        }

        @include devicesMax(mobile) {
          font-size: 4vw;
        }
      }
    }

    .btnGetDirection {
      background: transparent;
      box-shadow: 0 0 0 1pt #ffffff75;
      border-radius: 28px;
      cursor: none;
      margin-top: 2.5vw;
      outline: none;
      padding: 0.5rem 1rem;
      text-decoration: none;
      @extend .flexCenterX;

      @include devicesMin(aboveTablet) {
        &:hover {
          background: #d9ff51;
          box-shadow: 0 0 0 1pt #d9ff51;
          outline: none;

          .btnText {
            color: #000000;
          }

          .arrowW {
            * {
              path {
                fill: #000;
              }
            }
          }
        }
      }

      @include devicesMax(tablet) {
        padding: 0.25rem 0.75rem;
      }

      @include devicesMax(mobile) {
        padding: 0.5rem 1rem;
      }

      .btnText {
        color: #f8f9fa;
        cursor: none;
        font-family: VisbyCF-Medium;
        font-size: 14px;
        line-height: 100%;
        transform: translateY(0.1ch);
        white-space: nowrap;

        @include devicesMax(tablet) {
          font-size: 12px;
        }

        @include devicesMax(mobile) {
          font-size: 14px;
        }

        @include devicesMax(mobile) {
          font-size: 4vw;
        }
      }

      .arrowW {
        margin-left: 0.5rem;
      }
    }
  }

  // CONTACT AND WORKS FOOTER STYLES
  .contactFooter,
  .projectFooter {
    .contact {
      padding: 4vw 5vw 3vw 5vw;

      display: flex;
      gap: 3vw;

      @include devicesMax(tablet) {
        padding: 10vw 5vw 3vw 5vw;
        gap: 10vw;
      }

      @include devicesMax(tabletMini) {
        gap: 5vw;
      }

      @include devicesMax(mobile) {
        flex-direction: column;
        padding: 15vw 0;
        gap: 20vw;
      }
    }

    .colTitle {
      font-family: VisbyCF-DemiBold;
      font-size: 1.75vw;
      line-height: 100%;
      margin: 0 0 2vw 0;
      color: #f8f9fa;

      @include devicesMax(mobile) {
        text-align: center;
        font-size: 7vw;
        margin: 0 0 4vw 0;
      }

      @include devicesMax(mobile) {
        font-size: 7vw;
        margin: 0 0 6vw 0;
      }
    }

    .colText {
      font-family: VisbyCF-Regular;
      font-size: 1rem;
      line-height: 180%;
      color: #b1b1b1;
      margin-top: 0;
      width: 65%;

      @include devicesMax(tablet) {
        font-size: 14px;
        width: 100%;
      }

      @include devicesMax(tabletMini) {
        font-size: 12px;
      }

      @include devicesMax(mobile) {
        font-size: 1rem;
        line-height: 200%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 5vw;
        width: 85%;
        text-align: center;
      }

      br {
        display: none;

        @include devicesMax(mobile) {
          display: block;
        }
      }
    }

    .inlineLink {
      color: #b1b1b1;
      font-family: VisbyCF-DemiBold;
      font-size: 18px;
      line-height: 180%;
      text-decoration: underline;
    }

    .col {
      &:nth-child(1) {
        @include devicesMax(mobile) {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
        }
      }

      &:nth-child(2) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-left: 5vw;

        @include devicesMax(mobile) {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin: 0;
          width: 100%;
        }
      }

      &:nth-child(3) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-left: auto;

        @include devicesMax(mobile) {
          align-items: center;
          margin-left: 0;
        }

        .social {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          gap: 3vw;

          @include devicesMax(tablet) {
            align-items: center;
            gap: 6vw;
          }

          @include devicesMax(mobile) {
            gap: 10.5vw;
            margin-top: 4vw;
          }

          .icon {
            height: 2vw;

            @include devicesMax(tablet) {
              height: 1vw;
            }

            @include devicesMax(mobile) {
              height: 6vw;
            }
          }
        }
      }
    }
  }

  .copyright {
    padding: 6vw 4vw 4vw 4vw;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    @include devicesMax(tablet) {
      padding: 10vw 2.5vw 2vw 2.5vw;
    }

    @include devicesMax(mobile) {
      flex-direction: column-reverse;
      justify-content: center;
      gap: 7vw;
    }

    @include devicesMax(mobile) {
      margin-bottom: 5vw;
    }

    .text {
      color: #fff;
      display: inline-block;
      font-family: VisbyCF-Regular;
      font-size: 1rem;
      letter-spacing: 100%;
      text-align: left;

      @include devicesMax(mobile) {
        text-align: center;
      }

      @include devicesMax(mobile) {
        font-size: 14px;
      }
    }

    .links {
      margin-left: auto;

      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4vw;

      @include devicesMax(mobile) {
        margin-bottom: 0;
      }

      .link {
        color: #fff;
        font-family: VisbyCF-Regular;
        font-size: 1rem;
        letter-spacing: 100%;

        &:nth-child(1),
        &:nth-child(2) {
          text-decoration: underline;
          text-decoration-color: #ffffff75;
          cursor: pointer;

          @include devicesMax(mobile) {
            text-decoration-color: #ffffff75;
          }
        }
      }
    }

    .social {
      display: flex;
      align-items: center;
      gap: 4vw;
      margin: 0;
      margin-left: 10vw;

      @include devicesMax(mobile) {
        margin-left: 0;
      }

      @include devicesMax(mobile) {
        gap: 15vw;
      }

      .icon {
        height: 2rem;

        @include devicesMax(tablet) {
          height: 1.75rem;
        }
      }
    }
  }
}
