@import "assets/scss/utils.scss";
@import "assets/scss/PrivacyAndCookieModal.module.scss";

.fixedWrapper {
  position: fixed;
  width: 100vw;
  bottom: 0;
  left: 0;
  z-index: z-index("cookiePopup");

  display: flex;
  align-items: center;
  justify-content: center;

  .cookieWrapper {
    padding: 1rem 2rem;
    border-radius: 64px;
    background: #f2f2f2;
    border: 1px solid #000000;

    margin-left: auto;
    margin-right: auto;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3vw;
    margin: 1rem;

    @include devicesMax(mobile) {
      flex-direction: column;
      margin: 1rem;
      padding: 0.75rem 2rem;
      border-radius: 16px;
    }

    p {
      font-family: VisbyCF-Medium;
      font-size: 1rem;

      @include devicesMax(mobile) {
        font-size: 14px;
        line-height: 140%;
        text-align: center;
        width: 80%;
      }

      br {
        display: none;

        @include devicesMax(mobile) {
          display: block;
        }
      }

      span {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .btn {
      flex-shrink: 0;
      padding: 0.25rem 3rem;
      background: transparent;
      border-radius: 32px;
      border: 1px solid #000000;
      cursor: pointer;

      @include devicesMax(mobile) {
        padding: 0rem 3rem;
        margin-top: 2;
      }

      &:hover {
        background: #d9ff51;
      }

      .btnSuccess {
        font-family: VanguardCF-Medium;
        font-size: 2rem;
        transform: translateY(-2px);
        margin: 0;
        padding: 0;

        @include devicesMax(mobile) {
          font-size: 24px;
        }
      }
    }
  }
}
