@import "assets/scss/utils.scss";
@import "assets/scss/variables.scss";

.modal {
  @include position(fixed, 0, 0, 0, 0);
  @include dims(100vw, 100%);
  overflow: scroll;
  z-index: z-index("modal");
  transition: 0.4s all ease;

  display: flex;
  align-items: flex-start;
  justify-content: center;

  opacity: 0;
  visibility: hidden;
  pointer-events: none;

  &.open {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }

  .close {
    @include position(fixed, 2vw, 2vw, auto, auto);
    @include dims(3rem);
    background: #fff;
    border-radius: 50%;
    padding: 0.75rem;
    margin: 1rem;
    z-index: z-index("modalClose");
    @extend .cursorPointer;

    @include devicesMax(tablet) {
      @include position(fixed, 4vw, 4vw, auto, auto);
      @include dims(3rem);
    }

    @include devicesMax(mobile) {
      @include position(fixed, 3.5vw, 4vw, auto, auto);
      @include dims(2rem);
      padding: 0.5rem;
    }

    .icon {
      @include dims(100%);
    }
  }

  .content {
    z-index: z-index("modalContent");
    position: relative;
  }

  .backdrop {
    @include position(fixed, 0, 0, 0, 0);
    @include dims(100%);
    z-index: z-index("modalBackdrop");

    background: rgba(0, 0, 0, 0.75);
    backdrop-filter: blur(18px);
    -webkit-backdrop-filter: blur(18px);
  }
}
