@import "assets/scss/utils.scss";

.asymmetricMediaContainer {
  display: flex;
  align-items: stretch;
  justify-content: center;
  gap: 1.5vw;
  flex-wrap: nowrap;
  padding: 0.5vw 8vw;
  max-height: 100vh;

  @include devicesMax(mobile) {
    flex-wrap: wrap;
    gap: 4vw;
    max-height: unset;
    padding: 0.5vw 4vw;
    margin: 2vw 0;
  }

  .mediaWrapper {
    border-radius: 8px;
    background: #c5c5c5;
    overflow: hidden;
    position: relative;
    z-index: 10;

    &:nth-child(1) {
      flex-basis: 55%;

      @include devicesMax(mobile) {
        flex-basis: 100%;
      }
    }

    &:nth-child(2) {
      flex-basis: 45%;

      @include devicesMax(mobile) {
        flex-basis: 100%;
      }
    }
  }

  &.isLeft {
    .mediaWrapper {
      &:nth-child(1) {
        flex-basis: 45%;

        @include devicesMax(mobile) {
          flex-basis: 100%;
        }
      }

      &:nth-child(2) {
        flex-basis: 55%;

        @include devicesMax(mobile) {
          flex-basis: 100%;
        }
      }
    }
  }
}
