@import "assets/scss/utils.scss";

.marquee {
  margin: 2rem 0;

  .marqueeInner {
    display: flex;
    will-change: transform;
  }
}
