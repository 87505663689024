@import "assets/scss/utils.scss";

.header {
  @include dims(100%, 200px);
  background: #000;
  padding: 1rem;
  position: relative;
  @extend .flexBetweenX;

  &.light {
    background: #f2f2f2;
  }

  @include devicesMax(mobile) {
    @include dims(100%, auto);
    padding: 0.25rem;
  }

  .jdfxLogo {
    position: relative;
    transition: 0.2s all ease;
    z-index: z-index("logo");

    &:hover {
      @include devicesMin(tablet) {
        transform: scale(1.05);
      }
    }

    @include devicesMax(mobile) {
      width: 30vw;
    }
  }
}
