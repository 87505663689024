@import "assets/scss/utils.scss";

.xBurger {
  @include position(fixed, 4vw, 3vw, auto, auto);
  margin-right: 2vw;
  mix-blend-mode: difference;
  pointer-events: auto;
  z-index: z-index("hamburger");
  @extend .flexCenterX;
  @extend .cursorPointer;

  @include devicesMax(tablet) {
    @include position(fixed, 7vw, 3vw, auto, auto);
  }

  @include devicesMax(mobile) {
    @include position(fixed, 11vw, 3vw, auto, auto);
  }

  &.disabled {
    pointer-events: none;
  }

  &.open {
    .xBurgerTextW {
      .xBurgerText {
        transform: translateY(-100%) skew(-10deg);
      }
    }

    .lines {
      .line {
        &.horizontal1,
        &.horizontal2,
        &.horizontal3 {
          transform: translateX(-110%);
        }
      }

      .cross1W {
        .cross1 {
          transform: translateX(0);
          transition-delay: 0.4s;
        }
      }

      .cross2W {
        .cross2 {
          transform: translateX(0);
          transition-delay: 0.4s;
        }
      }
    }
  }

  .xBurgerTextW {
    @include dims(5vw, 2.5vw);
    overflow: hidden;
    flex-shrink: 0;

    display: flex;
    flex-direction: column;
    align-items: center;

    @include devicesMax(tablet) {
      @include dims(7vw, 4.5vw);
    }

    @include devicesMax(mobile) {
      @include dims(10vw, 7vw);
    }

    .xBurgerText {
      color: #fff;
      display: block;
      font-family: VanguardCF-Regular;
      font-size: 2vw;
      transition: 0.4s all ease;
      transform: translateY(0) skew(-10deg);

      @include devicesMax(tablet) {
        font-size: 3.5vw;
      }

      @include devicesMax(mobile) {
        font-size: 6vw;
      }
    }
  }

  .lines {
    @include dims(3vw);
    flex-shrink: 0;
    overflow: hidden;
    position: relative;

    @include devicesMax(tablet) {
      @include dims(4vw);
    }

    @include devicesMax(mobile) {
      @include dims(7vw);
      margin-left: 0.5rem;
    }

    .line {
      @include dims(100%, 1px);
      background: #fff;

      &.horizontal1 {
        @include position(absolute, 38%, 0, auto, 0);
        transition: 0.3s all ease;
      }

      &.horizontal2 {
        @include position(absolute, 50%, 0, auto, 0);
        transition: 0.4s all ease;
      }

      &.horizontal3 {
        @include position(absolute, 63%, 0, auto, 0);
        transition: 0.5s all ease;
      }
    }

    .cross1W {
      @include position(absolute, 50%, auto, auto, 50%);
      @include dims(70%, 1px);
      transform: rotate(45deg) translate(-50%, -50%);
      transform-origin: 0 50%;

      .cross1 {
        @include dims(100%);
        transform: translateX(160%);
        background: #fff;
        transition: 0.2s all ease;
      }
    }

    .cross2W {
      @include position(absolute, 50%, auto, auto, 50%);
      @include dims(70%, 1px);
      transform: rotate(-45deg) translate(-50%, -50%);
      transform-origin: 0 100%;

      .cross2 {
        @include dims(100%);
        transform: translateX(160%);
        background: #fff;
        transition: 0.3s all ease;
      }
    }
  }
}
