@import "assets/scss/variables.scss";
@import "assets/scss/utils.scss";

.absoluteFullPage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100vw;
}

.fixedFullPage {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100vw;
}

.lsWrapper {
  background: #f2f2f2;
  // pointer-events: none;
  @extend .fixedFullPage;
  @extend .flexCenterX;
  z-index: z-index("preloader");

  .logoLottie {
    height: 50vh;
    width: 50vh;
    clip-path: none;

    @include devicesMax(tablet) {
      height: 35vh;
      width: 35vh;
    }

    @include devicesMax(mobile) {
      height: 30vh;
      width: 30vh;
    }
  }

  .progressV2 {
    position: absolute;
    right: 2vw;
    bottom: 1vw;

    @include devicesMax(tablet) {
      right: 4vw;
      bottom: 4vw;
    }
  }
}

.loadingOHiddenWrapper {
  pointer-events: none;

  @extend .fixedFullPage;

  z-index: z-index("preloader-green");

  .oHidden1 {
    position: absolute;
    z-index: 300;
    overflow: hidden;
    //border: 2px solid red;
  }

  .oHidden2 {
    position: absolute;
    left: 33vw;
    z-index: 200;
    overflow: hidden;
    //border: 2px solid blue;
  }

  .oHidden3 {
    position: absolute;
    left: 66vw;
    z-index: 100;
    //width: 34vw;
    overflow: hidden;
    //border: 2px solid pink;
  }
}

.lDummyNavMenu {
  pointer-events: none;
  position: relative;
  width: 100vw;
  height: 100vh;
  background: #d9ff51;

  display: flex;
  align-items: center;
  justify-content: center;

  .textWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    @include devicesMax(tablet) {
      transform: translateY(-10vw);
    }

    .jdfxLogoMenu {
      position: absolute;
      top: 3vw;
      left: 0;
      width: 22vw;
      z-index: 999;

      @include devicesMax(tablet) {
        top: 0;
        left: -5vw;
        width: 27vw;
      }

      @include devicesMax(mobile) {
        top: 0;
        left: -7vw;
        width: 27vw;
      }
    }

    h1 {
      font-family: VanguardCF-ExtraBold;
      font-weight: 400;
      font-size: 21vw;
      line-height: 85%;
      letter-spacing: 0;
      margin: 0;
      text-decoration: none;
      color: #000000;
      overflow: visible;
      text-align: center;
      cursor: pointer;
      user-select: none;

      @include devicesMax(tablet) {
        font-size: 22vw;
      }

      @include devicesMax(mobile) {
        line-height: 100%;
        font-size: 20vw;
      }
    }
  }
}
