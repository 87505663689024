// VanguardCF
@font-face {
  font-family: VanguardCF-ExtraBold;
  src: url("./fontFiles/VanguardCF-ExtraBold.woff") format("woff"),
    url("./fontFiles/VanguardCF-ExtraBold.woff2") format("woff2"),
    url("./fontFiles/VanguardCF-ExtraBold.otf") format("opentype");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: VanguardCF-Bold;
  src: url("./fontFiles/VanguardCF-Bold.woff") format("woff"), url("./fontFiles/VanguardCF-Bold.woff2") format("woff2"),
    url("./fontFiles/VanguardCF-Bold.otf") format("opentype");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: VanguardCF-DemiBold;
  src: url("./fontFiles/VanguardCF-DemiBold.otf") format("opentype");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: VanguardCF-Medium;
  src: url("./fontFiles/VanguardCF-Medium.otf") format("opentype"),
    url("./fontFiles/VanguardCF-Medium.woff2") format("woff2"), url("./fontFiles/VanguardCF-Medium.woff") format("woff");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: VanguardCF-Regular;
  src: url("./fontFiles/VanguardCF-Regular.otf") format("opentype"),
    url("./fontFiles/VanguardCF-Regular.woff2") format("woff2"),
    url("./fontFiles/VanguardCF-Regular.woff") format("woff");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: VanguardCF-Light;
  src: url("./fontFiles/VanguardCF-Light.otf") format("opentype"),
    url("./fontFiles/VanguardCF-Light.woff2") format("woff2"), url("./fontFiles/VanguardCF-Light.woff") format("woff");
  font-style: normal;
  font-display: swap;
}

// VisbyCF
@font-face {
  font-family: VisbyCF-Heavy;
  src: url("./fontFiles/VisbyCF-Heavy.otf") format("opentype");
  //url("./fontFiles/VisbyCF-DemiBold.woff2") format("woff2"),
  //url("./fontFiles/VisbyCF-DemiBold.woff") format("woff");
  //font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: VisbyCF-DemiBold;
  src: url("./fontFiles/VisbyCF-DemiBold.otf") format("opentype"),
    url("./fontFiles/VisbyCF-DemiBold.woff2") format("woff2"), url("./fontFiles/VisbyCF-DemiBold.woff") format("woff");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: VisbyCF-Medium;
  src: url("./fontFiles/VisbyCF-Medium.otf") format("opentype"), url("./fontFiles/VisbyCF-Medium.woff2") format("woff2"),
    url("./fontFiles/VisbyCF-Medium.woff") format("woff");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: VisbyCF-Regular;
  src: url("./fontFiles/VisbyCF-Regular.otf") format("opentype"),
    url("./fontFiles/VisbyCF-Regular.woff2") format("woff2"), url("./fontFiles/VisbyCF-Regular.woff") format("woff");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: VisbyCF-Light;
  src: url("./fontFiles/VisbyCF-Light.otf") format("opentype"), url("./fontFiles/VisbyCF-Light.woff2") format("woff2"),
    url("./fontFiles/VisbyCF-Light.woff") format("woff");
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: VisbyCF-Thin;
  src: url("./fontFiles/VisbyCF-Thin.otf") format("opentype"), url("./fontFiles/VisbyCF-Thin.woff2") format("woff2"),
    url("./fontFiles/VisbyCF-Thin.woff") format("woff");
  font-style: normal;
  font-display: swap;
}

//Ageya Oblique
@font-face {
  font-family: Ageya-Oblique;
  src: url("./fontFiles/Ageya-Oblique.otf") format("opentype"), url("./fontFiles/Ageya-Oblique.woff2") format("woff2"),
    url("./fontFiles/Ageya-Oblique.woff") format("woff");
  font-display: swap;
}

// Atyp Display Retouch
@font-face {
  font-family: AtypDisplayRetouchMedium;
  src: url("./fontFiles/AtypDisplayRetouchMedium.woff2") format("woff2"),
    url("./fontFiles/AtypDisplayRetouchMedium.woff") format("woff");
  font-display: swap;
}

@font-face {
  font-family: AtypDisplayRetouchRegular;
  src: url("./fontFiles/AtypDisplayRetouchRegular.woff2") format("woff2"),
    url("./fontFiles/AtypDisplayRetouchRegular.woff") format("woff");
  font-display: swap;
}
