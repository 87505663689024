@import "assets/scss/utils.scss";

.scrollTracker {
  @include position(fixed, auto, 2vw, 2vw, auto);
  @include dims(8vw);
  background: #ffffff;
  border-radius: 50%;
  overflow: hidden;
  z-index: z-index("stickyEl");

  @include devicesMax(tablet) {
    @include dims(15vw);
  }

  .orange {
    @extend .absoluteCenter;
    @include dims(0);
    background: #d9ff51;
    border-radius: 50%;
    transition: 0.4s all ease-in-out;
  }

  @include devicesMin(aboveTablet) {
    &:hover {
      .orange {
        @include dims(101%);
      }
    }
  }

  .otherWorks {
    @extend .absoluteCenter;
    @include dims(78%, auto);
  }
}
