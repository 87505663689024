@import "./utils.scss";

.works {
  background: #f2f2f2;
  border-bottom-left-radius: 32px;
  border-bottom-right-radius: 32px;
  margin-top: -1px;
  overflow: hidden;
  padding-top: 3vw;
  transform: translateZ(10px);

  @include devicesMax(tablet) {
    padding-bottom: 4vw;
  }

  .worksFilter {
    column-gap: 3vw;
    margin: 0 2vw;
    padding: 0.5vw 0;
    display: flex;
    align-items: flex-end;

    @include devicesMax(tablet) {
      flex-direction: column;
      gap: 2vw;
      align-items: flex-start;
      padding: 0 0 1.5vw 0;
    }

    @include devicesMax(mobile) {
      align-items: center;
      padding: 5vw 0 10vw 0;
      flex-direction: column;
    }

    .title {
      color: #000000;
      font-family: VanguardCF-ExtraBold;
      font-size: 4vw;
      line-height: 100%;
      text-align: left;

      @include devicesMax(tablet) {
        font-size: 7vw;
      }

      @include devicesMax(mobile) {
        font-size: 23vw;
        margin-bottom: 5vw;
      }
    }

    .formContainer {
      display: flex;
      flex: 1;

      @include devicesMax(tablet) {
        @include dims(100%, auto);
        justify-content: flex-start;
      }

      @include devicesMax(mobile) {
        @include dims(95%, auto);
        flex-direction: column;
      }

      .inputContainer {
        @include dims(20vw, 3vw);
        position: relative;

        @include devicesMax(tablet) {
          @include dims(28vw, 4.5vw);
        }

        @include devicesMax(tabletMini) {
          @include dims(32vw, 4.5vw);
        }

        @include devicesMax(mobile) {
          @include dims(100%, 11vw);
          margin-bottom: 2vw;
        }

        &.search {
          margin-left: auto;
          width: 30.5vw;

          @include devicesMax(tablet) {
            margin-left: 0;
          }

          @include devicesMax(mobile) {
            flex: auto;
            margin-top: 4vw;
            padding: 0 2vw;
            width: 100%;
          }
        }

        .searchInput {
          @include dims(100%);
          background: transparent;
          border: none;
          border-bottom: 1px solid #bdbdbd;
          font-family: VisbyCF-Medium;
          font-size: 1.25vw;
          outline: none;
          padding-left: 2rem;

          @include devicesMax(mobile) {
            font-size: 5vw;
          }
        }

        .iconWrapper {
          @include position(absolute, 50%, auto, auto, 0.75rem);
          transform: translate(-50%, -50%);
        }

        .label {
          @include position(absolute, 0, 0, 0, 0);
          @include dims(100%);
          font-family: VisbyCF-Regular;
          font-size: 1.15vw;
          padding-left: 2rem;
          pointer-events: none;
          transition: 0.4s all ease;
          opacity: 0.3;

          display: flex;
          align-items: center;

          @include devicesMax(tablet) {
            font-size: 1.5vw;
          }

          @include devicesMax(mobile) {
            font-size: 4vw;
            padding-left: 8vw;
          }

          &.hidden {
            @extend .hide;
          }
        }
      }
    }

    .leftWrapper {
      margin-left: auto;

      @include devicesMax(mobile) {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 0;
        width: 90vw;
        padding-bottom: 5vw;
      }

      small {
        color: #000000;
        font-family: VanguardCF-Regular;
        font-size: 2vw;
        line-height: 100%;
        letter-spacing: 0px;
        text-align: left;

        @include devicesMax(tablet) {
          font-size: 3vw;
        }

        @include devicesMax(mobile) {
          font-size: 8vw;
        }
      }

      // .borderWrapper {
      //   border-bottom: 2px solid black;
      //   display: flex;
      //   padding-bottom: 2px;
      //   animation: fadeIn 0.3s ease;
      //   margin-bottom: -4px;

      //   .clearMobile {
      //     display: none;

      //     @include devicesMax(mobile) {
      //       display: block;
      //       font-size: 8vw;
      //     }
      //   }
      // }
    }

    form {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 2vw;
    }
  }

  .works {
    @include dims(100%, auto);
    @extend .flexCenterX;
    min-height: 70vh;

    @include devicesMax(tablet) {
      min-height: 75vh;
    }

    .worksList {
      padding: 5vw 2vw 2vw 2vw;

      display: grid;
      grid-template-columns: repeat(3, 30vw);
      align-items: center;
      justify-content: center;
      gap: 2vw;

      @include devicesMax(tablet) {
        grid-template-columns: repeat(2, 45vw);
        padding: 3vw 2vw 0 2vw;
      }

      @include devicesMax(mobile) {
        grid-template-columns: repeat(1, 94vw);
        padding: 5vw 0 10vw 0;
      }
    }

    .notFound {
      @extend .flexCenterY;
      @include dims(100%, auto);

      .notFoundText {
        font-family: VisbyCF-Regular;
        font-size: 1.5vw;
        line-height: 100%;
        padding: 3vw 0;

        @include devicesMax(tablet) {
          font-size: 2.5vw;
        }

        @include devicesMax(mobile) {
          font-size: 5vw;
          line-height: 160%;
          margin-top: 5vw;
          text-align: center;
          width: 70%;
        }

        .link {
          font-family: VisbyCF-DemiBold;
          text-decoration: underline;
        }
      }
    }
  }
}
