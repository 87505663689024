@import "assets/scss/utils.scss";

.imageRevealWrapper {
  @include dims(100%, 40vw);
  box-sizing: border-box;
  padding: 0.5vw 8vw;

  @include devicesMax(mobile) {
    padding: 0.5vw 4vw;
  }

  .imageWrapper {
    @include dims(100%);
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    z-index: 10;
    background: #dedede;

    .bgImg {
      @include position(absolute, 50%, auto, auto, 50%);
      transform: translate(-50%, -50%);
      @include dims(100%);
      object-fit: cover;
      z-index: 1;
    }

    .sImageWrapper {
      @include position(absolute, 0, auto, auto, 0);
      @include dims(100%);
      overflow: hidden;
      z-index: 5;

      .imgWrapper {
        @include position(absolute, 50%, auto, auto, 50%);
        @include dims(100%);
        transform: translate(-50%, -50%);
        object-fit: cover;
      }
    }
  }
}
