@import "assets/scss/normalize.scss";
@import "assets/scss/reset.scss";
@import "assets/scss/fonts/fonts.scss";
@import "assets/scss/utils.scss";

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000;
}

// .scrollbar-track {
//    background: transparent !important;
// }

main {
  position: relative;
  z-index: z-index("main");
}

* {
  cursor: default;
}

.LazyLoad {
  @include dims(100%);

  &.is-visible {
    opacity: 1;
  }
}

::-moz-selection {
  /* Code for Firefox */
  color: #000;
  background: #d9ff51;
}

::selection {
  color: #000;
  background: #d9ff51;
}

.lazy {
  opacity: 0;
  transition: 0.4s all ease;

  &.loaded {
    opacity: 1;
  }
}
