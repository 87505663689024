.content {
  background: #000;
  transition: 0.2s background ease;

  border-bottom-left-radius: 32px;
  border-bottom-right-radius: 32px;
  overflow: hidden;

  &.dark {
    background: #000;
  }
}
