@import "assets/scss/utils.scss";

.privacyAndCookieModal {
  @include dims(75%, auto);
  color: #ffffff;
  margin: 10vw auto;

  @include devicesMax(tablet) {
    margin: 15vw auto;
  }

  @include devicesMax(mobile) {
    @include dims(80%, auto);
    margin: 20vw auto;
    padding-right: 8vw;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: inline;
    }
  }

  h5 {
    font-family: VisbyCF-DemiBold;
    font-size: 2rem;
    margin: 4vw 0 1vw 0;

    @include devicesMax(mobile) {
      margin: 10vw 0 4vw 0;
      font-size: 1.75rem;
    }
  }

  p {
    font-family: VisbyCF-Regular;
    font-size: 1rem;
    line-height: 180%;
    margin-bottom: 1.5vw;

    @include devicesMax(mobile) {
      font-size: 13px;
    }
  }

  a {
    @include dims(75%, auto);
    text-decoration: underline;
    white-space: normal;
  }

  small {
    display: block;
    font-family: VisbyCF-Regular;
    font-style: italic;
    font-size: 0.85rem;
    margin-top: 4rem;
  }
}
