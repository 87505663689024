@import "assets/scss/utils.scss";

.magnetCursor {
  @include position(fixed, 0, auto, auto, 0);
  @include dims(1rem);
  mix-blend-mode: exclusion;
  opacity: 0;
  pointer-events: none;
  transition: 0.1s opacity ease;
  z-index: 10000000000;

  &.visible {
    opacity: 1;
  }

  @include devicesMax(tablet) {
    display: none;
  }

  .cursor {
    .inner {
      @include position(absolute, 50%, auto, auto, 50%);
      transform: translate(-50%, -50%);
      @extend .hide;

      &.active {
        @extend .show;
      }

      .texted {
        @include position(absolute, 50%, auto, auto, 50%);
        transform: translate(-50%, -50%) scale(0);
        @include dims(7.5rem);
        background: #d9ff51;
        border-radius: 50%;
        transition: 0.2s all ease;
        z-index: -1;
        @extend .hide;

        &.active {
          @extend .show;
          transform: translate(-50%, -50%) scale(1);
        }

        .text {
          @include position(absolute, 50%, auto, auto, 50%);
          transform: translate(-50%, -50%) skew(-10deg);
          color: #3b3b3b;
          font-family: VanguardCF-Regular;
          font-size: 3rem;
          transition: 0.4s opacity ease-in-out;
          white-space: nowrap;
        }
      }

      .award {
        @include position(absolute, 50%, auto, auto, 50%);
        transform: translate(-50%, -50%) scale(0);
        @include dims(7.5rem);
        @extend .hide;
        transition: 0.2s all ease;
        z-index: -1;

        &.active {
          @extend .show;
          transform: translate(-40%, -40%) scale(1);
        }
      }
    }

    .default {
      @include dims(1.5rem);
      background: #d9ff51;
      border-radius: 50%;
      position: relative;
      transform-origin: center center;
      transform: translate(-50%, -50%) scale(0);
      transition: 0.2s all ease;
      @extend .hide;

      &.active {
        @extend .show;
        transform: translate(-20%, -40%) scale(1);
      }
    }
  }
}
