@import "assets/scss/utils.scss";

.teamSlider {
  position: relative;
  display: none;

  @include devicesMax(tablet) {
    display: block;
  }

  .memberCard {
    background: #000000;
    margin: 10vw 0;
    padding: 0 5vw 0 7vw;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 2vw;

    @include devicesMax(mobile) {
      margin: 40vw 0 10vw 0;
      padding: 0 5vw;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }

    .imgWrapper {
      position: relative;

      .memberPhoto {
        @include dims(40vw, 50vw);
        border-radius: 16px;
        border: 2px solid #ffffff;
        flex-shrink: 0;
        object-fit: cover;

        @include devicesMax(mobile) {
          @include dims(90vw, 100vw);
          border-radius: 24px;
        }
      }
    }

    .info {
      color: #f8f9fa;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 1rem;

      @include devicesMax(mobile) {
        margin-top: 1vw;
        padding-left: 16px;
      }

      .credentials {
        font-family: VanguardCF-Bold;
        font-size: 6vw;
        line-height: 100%;
        margin-bottom: 1rem;

        @include devicesMax(mobile) {
          font-size: 11vw;
          margin-bottom: 0rem;
        }

        br {
          display: none;

          @include devicesMax(mobile) {
            display: block;
          }
        }
      }

      .role {
        border: 1px solid rgba(255, 255, 255, 0.5);
        border-radius: 32px;
        font-family: VisbyCF-Medium;
        font-size: 1.25vw;
        line-height: 100%;
        margin-bottom: 1rem;
        padding: 0.5rem 1.25rem;
        text-transform: uppercase;

        @include devicesMax(mobile) {
          border: none;
          font-size: 3.5vw;
          margin-bottom: 0;
          padding: 0;
        }
      }

      .linkedinLogo {
        @include dims(1.75rem);

        @include devicesMax(mobile) {
          @include dims(1.5rem);
        }
      }
    }

    .emojiWrapper {
      @include position(absolute, 0, 0, auto, auto);
      transform: translate(50%, -50%);
      @extend .flexCenterX;

      @include devicesMax(mobile) {
        transform: translate(50%, -50%);
        right: 75vw;
      }

      .emojiBg {
        @include dims(15vw);

        @include devicesMax(mobile) {
          @include dims(35vw);
        }
      }

      .emoji {
        @include dims(auto, 4vw);
        position: absolute;

        @include devicesMax(mobile) {
          @include dims(auto, 10vw);
        }
      }
    }

    .keepScrooolling {
      position: absolute;
      right: 10vw;
      bottom: 3vw;

      @extend .flexCenterX;
      gap: 1rem;

      @include devicesMax(tablet) {
        bottom: 25vw;
      }

      @include devicesMax(mobile) {
        display: none;
      }

      h6 {
        color: #f8f9fa;
        font-family: VanguardCF-Regular;
        font-weight: 100;
        font-size: 1.5vw;
        line-height: 100%;
        margin: 0;
        letter-spacing: 0;
        white-space: nowrap;

        @include devicesMax(tablet) {
          font-size: 2.5vw;
        }
      }

      .downArrow {
        transform: rotate(-90deg);
        width: 1.5vw;
      }
    }
  }

  .btnWrapper {
    cursor: none;
    display: flex;
    height: fit-content;
    transition: 0.3s all ease;
    width: 120px;
    z-index: 10;

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    @extend .show;

    @include devicesMax(mobile) {
      top: 50%;
    }

    &.disabled {
      @extend .hide;
    }

    &.btnPrev {
      justify-content: flex-start;
      left: 5vw;

      @include devicesMax(tabletMini) {
        left: 4vw;
      }

      @include devicesMax(mobile) {
        left: 7vw;
      }
    }

    &.btnNext {
      justify-content: flex-end;
      right: 5vw;

      @include devicesMax(mobile) {
        right: 7vw;
      }
    }

    .btnInner {
      @include dims(90px, 50px);
      background: #f2f2f2;
      border: 0.5px solid #000000;
      border-radius: 32px;
      cursor: none;
      position: relative;
      transition: 0.3s all ease;
      @extend .flexCenterX;

      @include devicesMax(tablet) {
        @include dims(50px);
        border-radius: 50%;
      }

      @include devicesMax(mobile) {
        @include dims(40px);
        background: #d9ff51;
      }

      .btnArrow {
        cursor: none;
        pointer-events: none;

        &.next {
          transform: rotate(45deg);
        }

        &.prev {
          transform: rotate(-135deg);
        }
      }
    }

    @include devicesMin(aboveTablet) {
      &:hover {
        .btnInner {
          @include dims(50px);
          background: #d9ff51;
          border: 1px solid #d9ff51;
          cursor: none;
        }
      }
    }
  }
}
