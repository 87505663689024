@import "assets/scss/utils.scss";

.otherWorks {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2vw;

  @include devicesMax(mobile) {
    padding: 10vw 0;
  }

  .sliderItem {
    @extend .flexCenterX;

    @include devicesMax(mobile) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 5vw;
      padding: 0 5vw;
    }

    .mediaWrapper {
      @include dims(45vw, 30vw);
      border-radius: 20px;
      overflow: hidden;
      position: relative;
      z-index: 10;

      @include devicesMax(mobile) {
        @include dims(100%, 70vw);
      }

      .media {
        @include dims(100%);
        display: block;
        object-fit: cover;
        object-position: center;
      }

      .award {
        position: absolute;
        right: 1vw;
        bottom: 1vw;
        width: 12vw;
        z-index: 100;
        border-radius: none;

        @include devicesMax(mobile) {
          right: 3vw;
          bottom: 2vw;
          width: 35vw;
        }
      }
    }

    .info {
      @include dims(35%, auto);
      margin-left: 2vw;

      @include devicesMax(mobile) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 90vw;
      }

      .cardTagWrapper {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-bottom: 1vw;

        .cardTag {
          border: 1px solid #787878;
          border-radius: 32px;
          display: inline-block;
          font-family: VisbyCF-DemiBold;
          font-size: 0.75rem;
          margin: 0 0.5vw 0.5vw 0;
          line-height: 100%;
          padding: 0.4rem 0.75rem 0.3rem 0.75rem;
          text-align: left;
          text-transform: uppercase;

          @include devicesMax(tablet) {
            padding: 0.25rem 0.75rem;
            font-size: 10px;
          }

          @include devicesMax(mobile) {
            margin: 1vw 1vw 1vw 0;
            font-size: 12px;
          }
        }
      }

      .title {
        text-align: left;
        font-family: VanguardCF-Bold;
        font-size: 3.5vw;
        line-height: 100%;
        letter-spacing: 0px;
        margin: 0 0 1.75vw 0;

        @include devicesMax(mobile) {
          font-size: 16vw;
          margin: 0 0 7vw 0;
        }
      }

      .text {
        p {
          text-align: left;
          font-family: VisbyCF-Regular;
          font-size: 1rem;
          line-height: 180%;
          letter-spacing: 0px;
          margin: 0 0 0.5vw 0;
          width: 27vw;

          @include devicesMax(tablet) {
            font-size: 12px;
            line-height: 150%;
            width: 30vw;
          }

          @include devicesMax(mobile) {
            font-size: 16px;
            line-height: 200%;
            margin: 0 0 7vw 0;
            width: 100%;
          }
        }
      }

      .cardBtn {
        border: 1px solid #000;
        display: inline-block;
        margin-top: 2vw;
        border-radius: 32px;
        padding: 0.5rem 1.5rem;
        @extend .cursorPointer;

        @include devicesMin(aboveTablet) {
          &:hover {
            background: #d9ff51;
            cursor: none;
          }
        }

        .btnText {
          font-family: VisbyCF-Medium;
          font-size: 1.25vw;
          margin: 0;

          @include devicesMax(mobile) {
            font-size: 4.5vw;
          }
        }
      }
    }

    &.dark {
      .info {
        .title {
          color: #f2f2f2;
        }

        .text {
          color: #787878;
        }

        .cardBtn {
          border: 1px solid #787878;

          .btnText {
            color: #f2f2f2;
          }

          &:hover {
            background: #d9ff51;
            border: 1px solid #d9ff51;

            .btnText {
              color: #000;
            }
          }
        }
      }
    }

    &.light {
      .cardTagWrapper {
        .cardTag {
          border: 1px solid #000;
          color: #000;
        }
      }

      .info {
        .title {
          color: #000;
        }

        .text {
          color: #000;
        }

        .cardBtn {
          border: 1px solid #000;

          .btnText {
            color: #000;
          }

          &:hover {
            background: #d9ff51;
          }
        }
      }
    }
  }

  .btnWrapper {
    cursor: none;
    display: flex;
    height: fit-content;
    transition: 0.3s all ease;
    width: 120px;
    z-index: 10;

    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    @extend .show;

    @include devicesMax(mobile) {
      transform: translateY(-160%);
    }

    &.disabled {
      @extend .hide;
    }

    &.btnPrev {
      justify-content: flex-start;
      left: 5vw;

      @include devicesMax(tabletMini) {
        left: 4vw;
      }

      @include devicesMax(mobile) {
        left: 6vw;
      }
    }

    &.btnNext {
      justify-content: flex-end;
      right: 5vw;

      @include devicesMax(mobile) {
        right: 6vw;
      }
    }

    .btnInner {
      @include dims(90px, 50px);
      background: #f2f2f2;
      border: 0.5px solid #000000;
      border-radius: 32px;
      cursor: none;
      position: relative;
      transition: 0.3s all ease;
      @extend .flexCenterX;

      @include devicesMax(tablet) {
        @include dims(50px);
        background: #d9ff51;
        border-radius: 50%;
      }

      @include devicesMax(mobile) {
        @include dims(40px);
      }

      .btnArrow {
        cursor: none;
        pointer-events: none;

        &.next {
          transform: rotate(45deg);
        }

        &.prev {
          transform: rotate(-135deg);
        }
      }
    }

    @include devicesMin(aboveTablet) {
      &:hover {
        .btnInner {
          @include dims(50px);
          background: #d9ff51;
          border: 1px solid #d9ff51;
          cursor: none;
        }
      }
    }
  }
}
