@import "assets/scss/variables.scss";
@import "assets/scss/utils.scss";

.progressWrapper {
  .progress {
    h5 {
      font-family: VanguardCF-Bold;
      font-size: 10vw;
      margin: 0;

      @include devicesMax(tablet) {
        font-size: 15vw;
      }
    }
  }
}
