@import "assets/scss/utils.scss";

.card {
  margin-bottom: auto;
  padding-bottom: 5vw;
  @extend .cursorPointer;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;

  @include devicesMax(mobile) {
    margin-bottom: 10vw;
  }

  .mediaContainer {
    @include dims(100%, 21vw);
    background: #dedede;
    border-radius: 16px;
    overflow: hidden;
    position: relative;
    z-index: 10;

    @include devicesMax(tablet) {
      @include dims(100%, 30vw);
    }

    @include devicesMax(mobile) {
      @include dims(100%, 65vw);
    }

    .award {
      position: absolute;
      right: 1vw;
      bottom: 1vw;
      width: 12vw;
      z-index: 100;
      border-radius: none;

      @include devicesMax(mobile) {
        right: 3vw;
        bottom: 3vw;
        width: 30vw;
      }
    }

    .video,
    .image {
      display: block;
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }
  }

  .title {
    color: #000000;
    font-family: VisbyCF-DemiBold;
    font-size: 1.75vw;
    line-height: 100%;
    letter-spacing: 0px;
    margin: 1.75vw 0 1vw 0;
    text-align: left;
    text-align: center;

    @include devicesMax(tablet) {
      font-size: 3vw;
    }

    @include devicesMax(mobile) {
      font-size: 6vw;
      line-height: 120%;
      margin: 3vw auto 0 auto;
      padding-left: 0;
    }
  }
}
