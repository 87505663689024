@import "assets/scss/variables.scss";
@import "assets/scss/utils.scss";

.about {
  .intro {
    @extend .flexCenterY;
    position: relative;
    padding: 0 0 5vw 0;

    @include devicesMax(mobile) {
      padding: 17vw;
    }

    .titleWrapper {
      @extend .flexCenterY;
      color: #f8f9fa;
      transform: translateX(-2vw);
      width: 58vw;

      @include devicesMax(mobile) {
        width: 90vw;
      }

      .title {
        font-size: 10vw;
        font-family: VanguardCF-ExtraBold;
        line-height: 100%;
        width: 100%;

        span {
          font-family: Ageya-Oblique;
        }

        br {
          display: none;
        }

        @include devicesMax(mobile) {
          font-size: 20vw;

          br {
            display: inline-block;
          }
        }

        &:nth-child(1) {
          text-align: left;

          @include devicesMax(mobile) {
            font-size: 27vw;
            line-height: 80%;
            text-align: center;
            margin-bottom: 5vw;
          }
        }

        &:nth-child(2) {
          text-align: right;
          transform: translateY(-1vw);

          @include devicesMax(mobile) {
            font-size: 19vw;
            transform: translateY(0);
            text-align: center;
          }
        }

        &:nth-child(3) {
          margin-right: 9vw;
          text-align: right;
          transform: translateY(-3vw);

          @include devicesMax(mobile) {
            transform: translateY(-1vw);
            text-align: center;
            margin-right: 0;
          }
        }
      }
    }

    .flyingTextWrapper {
      @include dims(55vw, auto);
      padding-top: 5vw;
      margin-left: 5vw;
      transform: translateX(-10vw);

      display: flex;
      align-items: center;
      justify-content: space-between;

      @include devicesMax(mobile) {
        width: 100vw;
        padding-top: 20vw;
        flex-direction: column;
        transform: translateX(0);
        margin: 0;
      }

      small {
        @include dims(22vw, auto);
        color: #f8f9fa;
        display: block;
        font-family: VisbyCF-DemiBold;
        font-size: 1.1vw;
        line-height: 200%;

        @include devicesMax(mobile) {
          @include dims(90vw, auto);
          font-weight: 700;
          font-size: 18px;
        }

        &:nth-child(1) {
          text-align: left;

          @include devicesMax(mobile) {
            text-align: center;
          }
        }

        &:nth-child(2) {
          text-align: right;
          margin-top: 15vw;
          max-width: 23ch;
          transform: translateY(5vw);

          @include devicesMax(mobile) {
            margin-top: 0;
            text-align: center;
            max-width: none;
          }
        }

        .newLine {
          display: block;
          font-family: VisbyCF-DemiBold;
          font-weight: 700;
          margin: 1rem 0;
        }

        .emoji {
          padding: 0 0.5rem;
        }
      }
    }

    .verticalTextLeft {
      position: absolute;
      top: 18vw;
      left: 5vw;

      @include devicesMax(mobile) {
        display: none;
      }

      img {
        @include devicesMax(tablet) {
          width: 7vw;
        }
      }
    }

    .verticalTextRight {
      position: absolute;
      top: 65vw;
      left: 93vw;

      @include devicesMax(mobile) {
        display: none;
      }

      @media (max-width: 390px) {
        top: 180vh;
      }

      @media (max-width: 320px) {
        top: 205vh;
      }

      h6 {
        color: #f8f9fa;
        font-family: VanguardCF-Regular;
        font-weight: 100;
        font-size: 2.2vw;
        line-height: 100%;
        margin: 0;
        letter-spacing: 0;
        writing-mode: vertical-rl;
        white-space: nowrap;

        @include devicesMax(mobile) {
          display: none;
          // font-size: 10vw;
        }
      }

      .downArrow {
        width: 2vw;

        @include devicesMax(mobile) {
          width: 8vw;
          margin-top: 1rem;
        }
      }
    }

    .downArrowMobile {
      display: none;

      @include devicesMax(mobile) {
        display: block;
        margin-top: 20vw;
      }
    }

    .teamBlob {
      position: absolute;
      right: 0;
      top: 10vw;
      width: 64vw;

      @include devicesMax(mobile) {
        top: 9.5%;
        width: 95vw;
      }
    }

    .teamSmile {
      position: absolute;
      right: 13vw;
      top: 3vw;
      width: 16vw;

      @include devicesMax(mobile) {
        top: 12%;
        right: -4vw;
        width: 25vw;
      }
    }
  }

  .whatWeDo {
    @include dims(100vw, auto);
    @extend .flexCenterX;
    color: #f8f9fa;
    padding: 10vw 0 0.5vw 0;
    z-index: -5;

    @include devicesMax(tablet) {
      padding: 20vw 0 0 0;
    }

    @include devicesMax(mobile) {
      padding: 10vw 0 0 0;
    }

    br {
      display: none;

      @include devicesMax(mobile) {
        display: block;
      }
    }

    h1 {
      display: block;
      font-size: 18vw;
      line-height: 100%;
      font-family: VanguardCF-ExtraBold;
      text-align: center;
      transform: translateY(100%);
      width: 100%;

      @include devicesMax(mobile) {
        font-size: 37vw;
        line-height: 90%;
        transform: translateY(0);
      }
    }
  }

  .fields {
    background: #f2f2f2;
    border-radius: 64px;
    padding: 10vw;
    position: relative;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2.5vw;

    @include devicesMax(tablet) {
      gap: 20px;
      padding: 10vw 7vw;
    }

    @include devicesMax(mobile) {
      border-radius: 28px;
      padding: 15vw 2vw;

      grid-template-columns: repeat(1, 1fr);
    }

    &:before {
      content: "";
      @include position(absolute, 0, 0, auto, 0);
      @include dims(100%, 350px);
      background: linear-gradient(0deg, #000, rgb(255, 255, 255, 0));
      transform: translateY(-50%);
      z-index: -1;
    }

    .fieldCard {
      @include dims(auto, 27vw);
      border-radius: 48px;
      border: 2px solid #000000;
      padding: 1.5vw 2.5vw 2.5vw 2.5vw;
      position: relative;
      cursor: pointer;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      @include devicesMin(aboveTablet) {
        &:hover {
          background: #000000;

          h5 {
            color: #f8f9fa;
          }

          .seeAllWorks {
            visibility: visible;
            margin-top: auto;
            opacity: 1;
          }

          p {
            visibility: hidden;
          }
        }
      }

      @include devicesMax(tablet) {
        @include dims(auto, 38vw);
        border-radius: 4vw;
        padding: 2vw 2.5vw 2.5vw 2.5vw;
      }

      @include devicesMax(mobile) {
        @include dims(100%, auto);
        border-radius: 24px;
        padding: 7vw;
      }

      .index {
        color: #000000;
        font-family: VanguardCF-Regular;
        font-weight: 400;
        font-size: 1.8vw;
        line-height: 100%;
        margin-bottom: 0.5vw;
        text-align: left;

        @include devicesMax(tablet) {
          font-size: 2.5vw;
        }

        @include devicesMax(mobile) {
          font-size: 7vw;
          margin-bottom: 5vw;
        }
      }

      .title {
        color: #000000;
        font-family: VanguardCF-Bold;
        font-size: 4.5vw;
        line-height: 100%;
        text-align: left;

        @include devicesMax(mobile) {
          font-size: 15vw;
        }
      }

      .description {
        color: #000000;
        font-family: VisbyCF-Medium;
        font-size: 1.1vw;
        line-height: 200%;
        margin-top: 2vw;
        text-align: left;

        @include devicesMax(tablet) {
          font-size: 1.25vw;
        }

        @include devicesMax(mobile) {
          font-size: 4.75vw;
          margin-top: 5vw;
        }
      }

      .seeAllWorks {
        display: block;
        position: absolute;
        left: 40px;
        bottom: 40px;
        margin-top: auto;
        user-select: none;

        padding: 0;
        margin: 0;

        font-family: VanguardCF-ExtraBold;
        color: #f8f9fa;
        font-size: 6.5vw;
        line-height: 100%;
        text-decoration: none;
        list-style: none;

        visibility: visible;
        opacity: 0;

        transition: 0.3s all ease;

        @include devicesMax(tablet) {
          color: #000000;
          font-size: 5vw;
          visibility: visible;
          opacity: 1;
          position: relative;
          left: 0;
          bottom: 0;
          margin-top: auto;
        }

        @include devicesMax(mobile) {
          font-size: 15vw;
          margin-top: 10vw;
        }
      }

      .pin {
        @include position(absolute, 95%, auto, auto, -11%);
        @include dims(14%, auto);

        @include devicesMax(mobile) {
          display: none;
        }
      }
    }
  }

  .awards {
    padding: 5vw 5vw 10vw 5vw;

    @include devicesMax(tablet) {
      padding: 15vh 5vw 10vh 5vw;
    }

    @include devicesMax(mobile) {
      padding: 15vh 5vw 5vh 5vw;
    }

    .awardsTitle {
      color: #ffffff;
      display: inline-block;
      font-size: 18vw;
      font-family: VanguardCF-ExtraBold;
      letter-spacing: 0;
      line-height: 60%;
      margin: 0;
      margin-bottom: 15vh;
      text-align: left;
      width: 100%;

      @include devicesMax(tablet) {
        margin-bottom: 5vh;
      }

      @include devicesMax(mobile) {
        font-size: 25vw;
        margin-bottom: 10vh;
        text-align: center;
      }

      .s {
        color: #ffffff;
        font-family: VanguardCF-ExtraBold;
        font-size: 0.5ch;

        // &::after {
        //   position: absolute;
        //   background: #ffffff;
        //   border-radius: 50%;
        //   display: inline;
        //   height: 3rem;
        //   width: 3rem;
        // }
      }
    }

    .row {
      display: grid;
      grid-template-columns: 25vw 1fr 5vw;

      @include devicesMax(tablet) {
        grid-template-columns: 40vw 1fr 10vw;
      }

      @include devicesMax(mobile) {
        grid-template-columns: auto;
        grid-template-rows: auto 1fr;
      }

      &.title {
        border-bottom: 1px solid #ffffff;
        padding: 1rem 0;

        @include devicesMax(mobile) {
          border-bottom: none;
        }
      }

      &.item {
        border-bottom: 1px solid #ffffff41;
        cursor: pointer;
        padding: 3rem 0;
        position: relative;

        @include devicesMax(mobile) {
          border-bottom: none;
          padding: 1rem 0;
        }
      }

      .text,
      .link {
        color: #ffffff;
        cursor: pointer;
        display: inline-block;
        font-family: VisbyCF-Medium;
        font-size: 1vw;
        line-height: 100%;
        text-align: left;

        @include devicesMax(tablet) {
          font-size: 1.8vw;
        }

        @include devicesMax(mobile) {
          font-size: 3vw;

          &:nth-child(1) {
            opacity: 0.4;
            margin-bottom: 2vh;
          }

          &:nth-child(2) {
            font-size: 4vw;
            margin-bottom: 3vh;
          }
        }
      }
    }

    .trophy {
      height: 4vw;
      width: 2.75vw;
      object-fit: contain;

      position: absolute;
      top: 50%;
      transform: translateY(-50%) translateX(-150%);

      @include devicesMax(tablet) {
        display: none;
      }
    }

    .table {
      padding-left: 13vw;
      padding-right: 10vw;

      @include devicesMax(tablet) {
        padding-left: 0;
        padding-right: 0;
      }

      @include devicesMax(mobile) {
        display: none;
      }
    }

    .tableMobile {
      border-bottom: 1px solid #ffffff41;
      display: none;
      position: relative;

      @include devicesMax(mobile) {
        display: block;
      }

      .pentawardsLogo {
        position: absolute;
        right: 2.5vw;
        bottom: 6vw;
        width: 12vw;
        transform: rotate(-20deg);
      }
    }
  }

  .references {
    @include dims(100vw, auto);
    position: relative;
    padding: 8vw 0;
    overflow: hidden;
    @extend .flexCenterY;

    @include devicesMax(mobile) {
      padding: 10vw 0;
    }

    .textWrapper {
      @include dims(60vw, auto);
      color: #f8f9fa;
      left: 18vw;

      @include devicesMax(mobile) {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }

      h3 {
        @include dims(100%, auto);
        font-family: VanguardCF-ExtraBold;
        line-height: 100%;

        &:nth-child(1) {
          font-size: 9vw;
          text-align: left;

          @include devicesMax(mobile) {
            font-size: 20vw;
            text-align: center;
          }
        }

        &:nth-child(2) {
          font-size: 4vw;
          margin-left: 12vw;
          margin-top: 0.8vw;
          text-align: left;

          @include devicesMax(mobile) {
            font-size: 20vw;
            margin: 0;
            margin-top: 2vw;
            text-align: center;
          }
        }

        &:nth-child(3) {
          font-size: 12.5vw;
          text-align: right;
          transform: translateY(-7vw);

          @include devicesMax(mobile) {
            font-size: 20vw;
            margin-top: 10vw;
            text-align: center;
          }
        }

        span {
          font-family: Ageya-Oblique;
          font-weight: 100;
        }
      }
    }

    .referencesSun {
      @include position(absolute, 22vw, auto, auto, 5vw);
      @include dims(22vw, auto);

      @include devicesMax(mobile) {
        @include position(relative, auto, auto, auto, auto);
        @include dims(65vw, auto);
        margin: 10vw 0;
      }
    }

    .companies {
      margin-left: 15vw;
      margin-top: 5vw;

      @include devicesMax(tablet) {
        margin: 0;
        margin-top: 15vw;
        width: 80vw;
      }

      @include devicesMax(mobile) {
        display: none;
      }
    }

    .companiesMobile {
      @extend .flexCenterX;
      flex-wrap: wrap;
      gap: 4vw;
      margin-top: 8vw;
      display: none;

      @include devicesMax(tabletMini) {
        display: flex;
      }

      .logoWrapper {
        @include dims(30vw, 15vw);
        @extend .flexCenterX;
        border: 1px solid #f8f9fa46;
        border-radius: 10px;
        margin-bottom: 4vw;

        @include devicesMax(mobile) {
          @include dims(40vw, 20vw);
        }
      }
    }
  }

  .marqueeSection {
    @include dims(100%, 100vh);
    overflow: hidden;
    position: relative;

    @include devicesMax(tablet) {
      @include dims(100%, 50vh);
    }

    @include devicesMax(mobile) {
      @include dims(100%, auto);
    }

    .mWrapper {
      width: 120vw;

      &.white {
        position: relative;
        transform: rotate(9deg) translateX(-10vw) translateY(15vw);
        -webkit-transform: rotate(9deg) translateX(-10vw) translateY(15vw);
        z-index: 10;

        @include devicesMax(mobile) {
          transform: rotate(9deg) translateY(20vw);
        }
      }

      &.gray {
        position: relative;
        transform: rotate(-13deg) translateX(-10vw);
        -webkit-transform: rotate(-13deg) translateX(-10vw);
        z-index: 5;

        .marqueeText {
          span {
            color: #101010;
          }
        }
      }

      .marqueeText {
        span {
          color: #f8f9fa;
          font-family: VanguardCF-ExtraBold;
          font-weight: 400;
          font-size: 12vw;
          line-height: 100%;
          white-space: nowrap;
          display: inline-block;

          @include devicesMax(mobile) {
            font-size: 25vw;
          }

          .inlineO {
            font-family: Ageya-Oblique;
            font-weight: 100;
            margin-left: -2.75vw;
            margin-right: 0;
          }
        }

        .rightMargin {
          margin-right: 2.5rem;
        }
      }
    }

    .eyeWrapper {
      @include position(absolute, 30vw, auto, auto, 20vw);
      z-index: 1000;
      transform: translateZ(100px);
      @extend .flexCenterY;

      @include devicesMax(tablet) {
        @include position(absolute, 30vw, auto, 15vw, 20vw);
      }

      @include devicesMax(mobile) {
        @include position(relative, auto, auto, auto, auto);
      }

      .teamEye {
        @include dims(10vw);
        margin-bottom: 1rem;

        @include devicesMax(tablet) {
          @include dims(20vw);
        }

        @include devicesMax(mobile) {
          @include dims(55vw);
          margin-bottom: 5vw;
        }
      }

      .eyeText {
        @include dims(100%, auto);
        color: #f8f9fa;
        font-size: 1.5vw;
        font-family: VanguardCF-Regular;
        line-height: 100%;
        text-align: center;

        @include devicesMax(tablet) {
          font-size: 2.5vw;
        }

        @include devicesMax(mobile) {
          font-size: 10vw;
        }
      }
    }

    .rocketWrapper {
      position: absolute;
      top: 24vw;
      left: 60vw;
      z-index: 100;
      padding: 1rem;
      transform: translateZ(100px);

      @include devicesMax(tablet) {
        top: 18vw;
        left: 55vw;
      }

      @include devicesMax(mobile) {
        display: none;
      }

      .rocket {
        @include devicesMax(tablet) {
          width: 25vw;
        }

        @include devicesMax(mobile) {
          width: 3vw;
        }
      }
    }

    .bombWrapper {
      position: absolute;
      top: 5vw;
      left: 40vw;
      z-index: 7;

      @include devicesMax(tablet) {
        top: 0;
        left: 30vw;
      }

      @include devicesMax(mobile) {
        display: none;
      }

      .bomb {
        @include devicesMax(tablet) {
          width: 20vw;
        }

        @include devicesMax(mobile) {
          width: 3vw;
        }
      }
    }
  }
}

.teamMembersDesktop {
  @include devicesMax(tablet) {
    display: none;
  }
}

//// horizontal scroll
.horizontalScroll {
  background: #000000;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: hidden;

  .progressBar {
    @include position(fixed, auto, auto, 0, 0);
    @include dims(100%, 2vw);
    background: #d9ff51;
    transform: translateZ(0);
    transform-origin: left bottom;
  }

  .progressBar {
    position: absolute;
    bottom: 0;
    left: 0;

    height: 12px;
    background: #d9ff51;
  }

  .fixedElement {
    display: flex;
    position: fixed;
    bottom: 2.5vw;
    left: 10vw;

    @include devicesMax(tablet) {
      align-items: flex-center;
      bottom: 10vw;
    }

    img {
      width: 1vw;

      @include devicesMax(tablet) {
        width: 2vw;
      }
    }

    p {
      text-align: center;
      letter-spacing: 0;
      font-family: VanguardCF-Regular;
      width: 100%;
      color: #f8f9fa;
      font-size: 2.5vw;
      line-height: 100%;
      margin: 0;
      padding-left: 1rem;
      transform: skew(-10deg);
      white-space: nowrap;
      margin-right: 1vw;

      @include devicesMax(tablet) {
        font-size: 5vw;
      }
    }
  }

  .horizontalScrollItem {
    @include dims(100vw, 100vh);

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;

    @include devicesMax(mobile) {
      align-items: center;
    }
  }

  .slide1 {
    //border: 1px solid orangered;
    width: fit-content;
  }
}

.memberCard {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2vw;

  padding: 0 0 0 10vw;
  background: #000000;

  @include devicesMax(tablet) {
    padding: 0 5vw 0 7vw;
  }

  @include devicesMax(mobile) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 90vw;
    padding: 0 5vw 0 5vw;
  }

  .imgWrapper {
    position: relative;

    .memberPhoto {
      @include dims(25vw, 30vw);
      border-radius: 16px;
      border: 2px solid #ffffff;
      flex-shrink: 0;
      object-fit: cover;

      @include devicesMax(tablet) {
        @include dims(50vw, 60vw);
      }

      @include devicesMax(mobile) {
        border-radius: 15vw;
        width: 90vw;
        height: 100vw;
      }
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 1rem;
    color: #f8f9fa;

    .credentials {
      font-size: 4.8vw;
      font-family: VanguardCF-Bold;
      line-height: 100%;
      margin: 0;
      margin-bottom: 1.5rem;
      letter-spacing: 0;
      width: 100%;

      @include devicesMax(tablet) {
        margin-top: 2vw;
        font-size: 8vw;
      }

      @include devicesMax(mobile) {
        font-size: 11vw;

        br {
          display: none;
        }
      }
    }

    .role {
      border: 1px solid rgba(255, 255, 255, 0.5);
      border-radius: 32px;
      position: relative;
      font-family: VisbyCF-Medium;
      font-size: 1rem;
      line-height: 100%;
      margin-bottom: 1rem;
      text-transform: uppercase;
      padding: 0.75rem 1.5rem;

      @include devicesMax(mobile) {
        font-size: 5.5vw;
        margin-top: -1vw;
      }
    }

    .linkedinLogo {
      @include dims(1.75rem);
    }
  }

  .emojiWrapper {
    @include position(absolute, 0, 0, auto, auto);
    transform: translate(50%, -50%);

    display: flex;
    align-items: center;
    justify-content: center;

    @include devicesMax(mobile) {
      transform: translate(50%, -40%);
      right: 75vw;
    }

    .emojiBg {
      @include dims(9vw);

      @include devicesMax(tablet) {
        @include dims(20vw);
      }

      @include devicesMax(mobile) {
        @include dims(35vw);
      }
    }

    .emoji {
      position: absolute;
      @include dims(auto, 3vw);

      @include devicesMax(tablet) {
        @include dims(auto, 6vw);
      }

      @include devicesMax(mobile) {
        height: 15vw;
      }
    }
  }

  .keepScrooolling {
    position: absolute;
    right: 10vw;
    bottom: 3vw;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    @include devicesMax(tablet) {
      bottom: 25vw;
    }

    @include devicesMax(mobile) {
      display: none;
    }

    h6 {
      color: #f8f9fa;
      font-family: VanguardCF-Regular;
      font-weight: 100;
      font-size: 1.5vw;
      line-height: 100%;
      margin: 0;
      letter-spacing: 0;
      white-space: nowrap;

      @include devicesMax(tablet) {
        font-size: 2.5vw;
      }
    }

    .downArrow {
      transform: rotate(-90deg);
      width: 1.5vw;
    }
  }
}

.social {
  display: flex;
  align-items: flex-start;
  gap: 1.25vw;
  margin-top: 1vw;

  @include devicesMax(mobile) {
    gap: 7vw;
  }

  img {
    height: 1.25vw;

    @include devicesMax(mobile) {
      height: 6vw;
    }

    /*        @include devicesMax(tablet) {
        height: 2.25rem;
      } */
  }
}
