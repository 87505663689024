@import "assets/scss/utils.scss";

.allMediaSlider {
  @include dims(100vw, 100%);
  background: #fff;
  overflow: hidden;

  .title {
    @extend .flexCenterX;
    color: #000000;
    font-family: VisbyCF-Medium;
    font-size: 1.25vw;
    line-height: 100%;
    text-align: center;
    padding: 3.25vw 0;

    @include devicesMax(tablet) {
      font-size: 3vw;
      padding: 5.75vw 0 4vw 0;
    }

    @include devicesMax(mobile) {
      font-size: 4vw;
      margin: 0 auto;
      padding: 10vw 0 2vw 0;
      width: 75vw;
    }
  }

  .slider {
    .mediaWrapper {
      @include dims(90vw, 80vh);
      margin: 0 auto;
      overflow: hidden;

      display: flex;
      align-items: center;
      justify-content: center;

      @include devicesMax(tablet) {
        @include dims(95vw, 80vh);
      }

      .video {
        width: auto;
        height: auto;
        max-height: 100%;
        max-width: 88vw;

        @include devicesMax(tablet) {
          max-width: 100vw;
          width: 100%;
        }
      }

      // .imgC {
      //   max-width: 88vw;
      //   max-height: 90vh;
      //   margin: 0 auto;
      //   display: block;
      //   object-fit: contain;

      //   @include devicesMax(tablet) {
      //     @include dims(100%, auto);
      //     max-height: 90vw;
      //   }

      //   @include devicesMax(mobile) {
      //     @include dims(94vw, auto);
      //     max-width: 100%;
      //     max-height: 100%;
      //   }
      // }

      // .video {
      //   // max-width: 88vw;
      //   // margin: 0 auto;
      //   display: block;
      //   object-fit: contain;

      //   @include devicesMax(tablet) {
      //     @include dims(100%, auto);
      //     max-width: 75vw;
      //   }

      //   @include devicesMax(mobile) {
      //     @include dims(94vw, auto);
      //     max-width: 100%;
      //     max-height: 100%;
      //   }
      // }
    }

    .btnWrapper {
      cursor: none;
      display: flex;
      height: fit-content;
      transition: 0.3s all ease;
      width: 120px;
      z-index: 10;

      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      @extend .show;

      &.disabled {
        @extend .hide;
      }

      &.btnPrev {
        justify-content: flex-start;
        left: 5vw;

        @include devicesMax(tabletMini) {
          left: 4vw;
        }

        @include devicesMax(mobile) {
          left: 5vw;
        }
      }

      &.btnNext {
        justify-content: flex-end;
        right: 5vw;

        @include devicesMax(mobile) {
          right: 5vw;
        }
      }

      .btnInner {
        @include dims(90px, 50px);
        background-color: #f2f2f2;
        border: 0.5px solid #000000;
        border-radius: 32px;
        cursor: none;
        position: relative;
        transition: 0.3s all ease;
        @extend .flexCenterX;

        @include devicesMax(tablet) {
          @include dims(50px);
          border-radius: 50%;
        }

        @include devicesMax(mobile) {
          @include dims(40px);
        }

        .btnArrow {
          cursor: none;
          pointer-events: none;

          &.next {
            transform: rotate(45deg);
          }

          &.prev {
            transform: rotate(-135deg);
          }
        }
      }

      @include devicesMin(aboveTablet) {
        &:hover {
          .btnInner {
            @include dims(50px);
            background: #d9ff51;
            border: 1px solid #d9ff51;
            cursor: none;
          }
        }
      }
    }
  }
}
