@import "../scss/utils.scss";

.figure {
  @include dims(100%);
  position: relative;

  .vid {
    @include dims(100%);
    display: block;
    z-index: z-index("img");

    position: absolute;
    top: 0;
    left: 0;
    -o-object-fit: cover;
    object-fit: cover;
    max-width: 100%;
  }

  .aspectRatio {
    padding-top: var(--aspectRatio);
  }
}
